<template>
  <v-btn
    v-if="pathWithRegistry"
    fab
    flat
    small
    :disabled="item.files && item.files.data.length == 0"
    :to="`/registries/${item.registries_id}/${entity}/${item.id}/view?files=1`"
  >
    <v-badge
      v-if="item.files && item.files.data"
      :color="item.files.data.length ? 'orange' : 'grey'"
      overlap
    >
      <span slot="badge">{{ item.files.data.length }}</span>
      <v-icon large>
        attachment
      </v-icon>
    </v-badge>
  </v-btn>

  <v-btn
    v-else
    fab
    flat
    small
    :disabled="item.files && item.files.data.length == 0"
    :to="`/${entity}/${item.id}/view?files=1`"
  >
    <v-badge
      v-if="item.files && item.files.data"
      :color="item.files.data.length ? 'orange' : 'grey'"
      overlap
    >
      <span slot="badge">{{ item.files.data.length }}</span>
      <v-icon large>
        attachment
      </v-icon>
    </v-badge>
  </v-btn>
</template>

<script>
export default {
  name: 'DataTableFileButtonLink',

  props: {
    item: {
      type: Object,
      required: true,
    },
    entity: {
      type: String,
      required: true,
    },
    pathWithRegistry: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>

<style></style>
