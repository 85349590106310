/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import VueAnalytics from 'vue-analytics'
import Router from 'vue-router'
import Meta from 'vue-meta'

// Routes
import paths from './paths'

// base directory
const baseDirectory = window.location.hostname === 'localhost' ? '' : '/'

function route (path, view, name) {
  return {
    name: name || view,
    path,
    component: (resovle) => import('@/views/' + view + '.vue').then(resovle)
  }
}

Vue.use(Router)

// Create a new router
const router = new Router({
  mode: 'history',
  base: baseDirectory,
  routes: paths
    .map((path) => route(path.path, path.view, path.name))
    .concat([{ path: '*', redirect: '/' }]),
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (
    to.name !== 'Connexion' &&
    to.name !== 'Nous Contacter' &&
    (!to.meta || !to.meta.extranet)
  ) {
    let user = JSON.parse(localStorage.getItem('user'))
    let session = localStorage.getItem('session')
    if (user && session !== null) {
      next()
    } else {
      next('/')
    }
  } else {
    next()
  }
})

Vue.use(Meta)

// Bootstrap Analytics
// Set in .env
// https://github.com/MatteoGabriele/vue-analytics
if (process.env.GOOGLE_ANALYTICS) {
  Vue.use(VueAnalytics, {
    id: process.env.GOOGLE_ANALYTICS,
    router,
    autoTracking: {
      page: process.env.NODE_ENV !== 'development'
    }
  })
}

export default router
