<template>
  <material-card
    v-bind="$attrs"
    class="v-card--material-infos mb-4"
    v-on="$listeners"
  >
    <v-card
      slot="offset"
      :class="`elevation-${elevation}`"
      :color="color"
      class="pa-4"
      dark
    >
      <v-icon
        size="40"
      >
        {{ icon }}
      </v-icon>
    </v-card>

    <div class="text-xs-right">
      <p
        class="category grey--text font-weight-light"
        v-text="title"
      />
      <p
        class="title font-weight-light"
        v-html="value"
      />
    </div>
    <template slot="actions">
      <slot name="butt" />
    </template>
  </material-card>
</template>

<script>
import Card from './Card'

export default {
  inheritAttrs: false,

  props: {
    ...Card.props,
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: undefined
    },
    value: {
      type: String,
      default: undefined
    }
  }
}
</script>

<style lang="scss">
    .v-card--material-infos {
    display: flex;
    flex-wrap: wrap;
    position: relative;

        .v-offset {
            display: inline-block;
            flex: 0 1;
            margin-top: 0;
            margin-left: 0;
            margin-right: auto;
            margin-bottom: 0 !important;
            max-width: auto;
            padding: 0 16px;
        }

        .v-card {
            border-radius: 4px;
            flex: 0 1 auto;
        }

        .v-card__text {
            display: inline-block;
            position: relative;
            top: -75px;
            right: 0;
            width: 100%;
        }
        .v-divider{
            margin-top:-80px !important;
        }
        .v-card__actions {
            flex: 1 0 100%;
            // margin-top:-80px !important;
        }
    }
</style>
