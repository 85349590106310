<template>
  <div
    id="cancelbtn"
    :class="[block ? '' : 'd-inline']"
  >
    <v-btn
      class="error"
      :block="block"
      @click="caution"
    >
      Annuler
      <v-icon right>
        cancel
      </v-icon>
    </v-btn>
    <!-- Confirm quit ? -->
    <v-dialog
      v-model="dialogQuit"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <h3>Annuler les modifications</h3>
        </v-card-title>

        <v-card-text>
          Confirmez-vous l'annulation ?
          <small
            class="grey--text"
          >Vos changements ne seront pas enregistrés</small>
        </v-card-text>
        <v-card-actions>
          <v-btn
            flat
            @click="dialogQuit = false"
          >
            <v-icon class="mr-2">
              cancel
            </v-icon>Non
          </v-btn>
          <v-spacer />
          <v-btn
            flat
            color="accent"
            @click="goBack"
          >
            <v-icon class="mr-2">
              delete
            </v-icon>Oui supprimer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'CancelButton',

  props: {
    isModified: {
      type: Boolean,
      required: true,
      default: false
    },
    block: {
      type: Boolean,
      default: true,
      required: false
    }
  },

  data: () => ({
    dialogQuit: false
  }),

  methods: {
    caution () {
      if (this.isModified) {
        console.log('caution')
        this.dialogQuit = true
      } else {
        console.log('BACK')
        this.goBack()
      }
    },

    goBack () {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    }
  }
}
</script>

<style></style>
