import { CustomerService } from '@/store/services/CustomerService'
import handleApiError from '@/helpers/handleApiError'

export default {
  get_customer (context, customersId) {
    return new Promise((resolve, reject) => {
      CustomerService.get_customer(customersId)
        .then(response => {
          var data = response.data
          context.commit('SET_CUSTOMER', data)
          resolve(data)
        })
        .catch(error => {
          handleApiError(error, reject)
        })
    })
  },
  get_customers (context) {
    return new Promise((resolve, reject) => {
      CustomerService.get_customers()
        .then(response => {
          var data = response.data
          context.commit('SET_CUSTOMERS', data)
          resolve(data)
        })
        .catch(error => {
          handleApiError(error, reject)
        })
    })
  },
  delete_customer_by_id (context, customersId) {
    return new Promise((resolve, reject) => {
      CustomerService.delete_customer_by_id(customersId)
        .then(response => {
          var data = response.data
          context.commit('DELETE_CUSTOMER', customersId)
          resolve(data)
        })
        .catch(error => {
          handleApiError(error, reject)
        })
    })
  },
  update_customer (context, customers) {
    return new Promise((resolve, reject) => {
      CustomerService.update_customer(customers)
        .then(response => {
          var data = response.data
          context.commit('ADD_CUSTOMER', customers)
          resolve(data)
        })
        .catch(error => {
          handleApiError(error, reject)
        })
    })
  },
  create_customer (context, customers) {
    return new Promise((resolve, reject) => {
      CustomerService.create_customer(customers)
        .then(response => {
          var data = response.data
          context.commit('ADD_CUSTOMER', customers)
          resolve(data)
        })
        .catch(error => {
          handleApiError(error, reject)
        })
    })
  }
}
