import { set } from '@/utils/vuex'

export default {
  SET_SPRINT_USER_STORIES: set('sprintUserStories'),
  SET_USER_STORIES: set('userStories'),
  SET_USER_STORY: set('userStory'),
  DELETE_USER_STORY: (state, userStoriesId) => {
    state.sprintUserStories = state.sprintUserStories.filter(item => { return item.id !== userStoriesId })
    state.userStories = state.userStories.filter(item => { return item.id !== userStoriesId })
    state.userStory = state.userStory.id !== userStoriesId ? state.userStory : {}
  },
  ADD_USER_STORIES: (state, userStories) => {
    let index = state.sprintUserStories.map(item => item.id).indexOf(userStories.id)
    index >= 0 ? state.sprintUserStories[index] = userStories : state.sprintUserStories.push(userStories)
    index = state.userStories.map(item => item.id).indexOf(userStories.id)
    index >= 0 ? state.userStories[index] = userStories : state.userStories.push(userStories)
    state.userStory = userStories
  }
}
