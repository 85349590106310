import { HTTP } from '@/plugins/axios'
import qs from 'qs'

const urlGroup = '/userStories'

export const UserStoryService = {
  get_sprint_user_stories (sprintsId) {
    return HTTP.get(`${urlGroup}/sprint/${sprintsId}`)
  },
  get_user_stories_by_id (userStoriesId) {
    return HTTP.get(`${urlGroup}/${userStoriesId}`)
  },
  delete_user_stories_by_id (userStoriesId) {
    return HTTP.delete(`${urlGroup}/${userStoriesId}`)
  },
  update_user_stories (userStories) {
    return HTTP.put(`${urlGroup}/${userStories.id}`, qs.stringify(userStories))
  },
  create_user_stories (userStories) {
    return HTTP.post(`${urlGroup}`, qs.stringify(userStories))
  }
}
