<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-layout
      row
      wrap
    >
      <v-flex
        md6
        xs12
      >
        <h4 class="subheading">
          <v-icon
            v-if="icon"
            class="mr-1"
          >
            {{ icon }}
          </v-icon>
          {{ label }}
        </h4>
        <slot />
        <v-layout row>
          <v-flex
            xs12
            md6
            class="mr-2"
          >
            <slot name="first" />
          </v-flex>
          <v-flex
            xs12
            md6
            class="ml-2"
          >
            <slot name="second" />
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex
        md6
        xs12
        class="hidden-xs-only pl-4"
      >
        <div
          v-if="help && helpTitle"
          class="help-border pl-3 mb-2"
          color="deep-puple"
        >
          <h3>
            <v-icon class="mr-1">
              help_outline
            </v-icon>
            {{ helpTitle }}
          </h3>
          <div
            v-if="helpContent"
            v-html="helpContent"
          />
          <div v-else>
            <slot name="content" />
          </div>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'EntityMetadataForm',
  props: {
    label: {
      type: String,
      required: false
    },
    icon: {
      type: String,
      required: false
    },
    help: {
      type: Boolean,
      required: false
    },
    helpTitle: {
      type: String,
      required: false
    },
    helpContent: {
      type: String,
      required: false
    }
  }
}
</script>

<style>
.help-border {
  border-left: 4px solid #673ab7;
}
</style>
