<template>
  <div class="uploader">
    <v-layout row wrap align-start>
      <div
        v-if="$refs[refForm] && $refs[refForm].dropActive"
        class="drop-active"
      >
        <!-- Fichier unique -->
        <h3 v-if="!isMultiple">
          Déposez le fichier pour l'ajouter
        </h3>
        <!-- Fichiers multiples -->
        <h3 v-else>
          Déposez les fichiers pour les ajouter
        </h3>
      </div>
      <v-flex xs12 lg12>
        <v-flex id="wrapper_uploadField" xs12 :class="{ droppable: canDrop }">
          <FileUpload
            :ref="refForm"
            v-model="filesArray"
            :input-id="refForm"
            :headers="headers"
            :directory="upDirectory"
            :accept="accept"
            :extensions="extensions"
            :drop-directory="dropdirectory"
            :drop="canDrop"
            :multiple="isMultiple"
            :maximum="maxAllowed"
            :thread="threads"
            :add-index="addindex"
            :post-action="postaction"
            @input-filter="inputFilter"
            @input-file="inputFile"
            @input="inputUpdate"
          >
            <!-- Drag and Drop -->
            <span v-if="canDrop">
              <v-icon large class="mr-2">
                mdi-folder
              </v-icon>
              Glissez-déposez vos fichiers ici ou
            </span>
            <!-- Fichiers multiples -->
            <v-btn v-if="isMultiple" color="success" :disabled="disabled">
              sélectionnez les fichiers
              <v-icon right>
                mdi-attachment
              </v-icon>
            </v-btn>
            <!-- Fichier unique -->
            <v-btn v-else color="info" :disabled="disabled" :loading="loading">
              Importer document
              <v-icon right>
                mdi-attachment
              </v-icon>
            </v-btn>
          </FileUpload>
        </v-flex>
        <!-- Upload manuel -->
        <v-flex v-if="!uploadAuto" xs12 lg6>
          <v-btn
            v-if="!$refs[refForm] || !$refs[refForm].active"
            color="info"
            class="sendFile"
            :disabled="filesArray.length == 0"
            @click.prevent="$refs[refForm].active = true"
          >
            Envoyer
            <v-icon right>
              mdi-backup
            </v-icon>
          </v-btn>
          <v-btn
            v-if="$refs[refForm] && $refs[refForm].active"
            color="warning"
            @click.prevent="$refs[refForm].active = false"
          >
            Stop
            <v-icon right>
              mdi-stop
            </v-icon>
          </v-btn>
        </v-flex>
      </v-flex>

      <!-- affichage fichier unique -->
      <v-flex v-if="!hideLog && filesArray.length && !isMultiple" xs12 lg12>
        <div v-for="file in filesArray" :key="file.id">
          <div v-if="file.active || file.progress !== '0.00'" class="progress">
            <div
              :class="{
                'progress-bar': true,
                'progress-bar-striped': true,
                error: file.error,
                'progress-bar-animated': file.active,
              }"
              role="progressbar"
              :style="{ width: file.progress + '%' }"
            >
              {{ Math.ceil(file.progress) }}%
            </div>
          </div>
          <span v-if="file.error" class="error">
            <v-icon class="mr-1" color="error"> mdi-error </v-icon>Erreur:
            {{ file.error }}
          </span>
        </div>
      </v-flex>
      <!-- Affichage log fichier unique -->
      <v-flex
        v-if="
          !hideLog &&
            filesArray.length &&
            $refs[refForm] &&
            $refs[refForm].uploaded &&
            !isMultiple
        "
        xs12
        lg12
      >
        <v-icon class="mr-1" color="success">
          mdi-clipboard-check
        </v-icon>
        Envoi OK<br />
        <div v-for="file in filesArray" :key="file.id">
          <img v-if="file.thumb" :src="file.thumb" height="20" />
          {{ file.name }} - <span>{{ file.size | formatSize }}</span>
        </div>
      </v-flex>
      <!-- Liste Fichiers multiples -->
      <v-flex
        v-if="filesArray.length > 0 && isMultiple"
        id="wrapper_list"
        xs12
        lg6
        class="px-2"
      >
        <v-data-table
          :headers="tableHeaders"
          :headers-length="nb_headers"
          :items="filesArray"
          class="elevation-1 tablefile"
          hide-actions
        >
          <template slot="headers" slot-scope="props">
            <tr>
              <th
                v-for="header in props.headers"
                :key="header.text"
                :class="['column px-2', 'text-xs-' + header.align]"
              >
                {{ header.text }}
              </th>
              <th v-if="!uploadAuto">
                Actions
              </th>
            </tr>
          </template>
          <template slot="items" slot-scope="props">
            <tr>
              <td class="text-xs-center" width="30px">
                <img
                  v-if="props.item.thumb"
                  :src="props.item.thumb"
                  height="20"
                />
              </td>
              <td class="text-xs-left text-truncate">
                {{ props.item.name }}
              </td>
              <td class="text-xs-left">
                {{ props.item.size | formatSize }}
              </td>
              <td class="text-xs-left" width="100px">
                <div
                  v-if="props.item.active || props.item.progress !== '0.00'"
                  class="progress"
                >
                  <div
                    :class="{
                      'progress-bar': true,
                      'progress-bar-striped': true,
                      error: props.item.error,
                      'progress-bar-animated': props.item.active,
                    }"
                    role="progressbar"
                    :style="{ width: props.item.progress + '%' }"
                  >
                    {{ Math.ceil(props.item.progress) }}%
                  </div>
                </div>
                <span v-if="props.item.error" class="error">
                  <v-icon class="mr-1" color="error"> mdi-error </v-icon>
                  Erreur: {{ props.item.error }}
                </span>
              </td>
              <td v-if="!uploadAuto" class="text-xs-center px-0">
                <v-btn
                  v-if="!uploadAuto && !props.item.success"
                  color="warning"
                  flat
                  fab
                  small
                  @click.prevent="remove(props.item)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
          <template slot="no-data">
            <v-alert :value="true" color="error" icon="warning">
              Aucun fichier
            </v-alert>
          </template>
        </v-data-table>
      </v-flex>

      <v-flex v-else xs12 sm6 />
    </v-layout>

    <!-- SNACKBAR -->
    <ui-SnackBarV2
      :text="text"
      :color="color"
      :bottom="bottom"
      :top="top"
      :right="right"
      :left="left"
      :snackbar="snackbar"
    />
  </div>
</template>

<script>
import { ENV } from '@/plugins/constant';
// https://lian-yue.github.io/vue-upload-component/#/documents#options-props-input-id
import vueUploadComponent from 'vue-upload-component';
import SnackBarMixin from '@/mixins/SnackBarMixin';
// import ImageCompressor from '@xkeshi/image-compressor' depreceated, use this instead : https://github.com/fengyuanchen/compressorjs

export default {
  name: 'Uploader',

  components: {
    FileUpload: vueUploadComponent,
  },

  filters: {
    formatSize: function(size) {
      if (size > 1024 * 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB';
      } else if (size > 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB';
      } else if (size > 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + ' MB';
      } else if (size > 1024) {
        return (size / 1024).toFixed(2) + ' KB';
      }
      return size.toString() + ' B';
    },
  },

  mixins: [SnackBarMixin],

  props: {
    url: {
      type: String,
      required: false,
      default: `${ENV}/files`,
    },

    isMultiple: {
      type: Boolean,
      required: false,
      default: true,
    },

    canDrop: {
      type: Boolean,
      required: false,
      default: true,
    },

    upDirectory: {
      type: Boolean,
      required: false,
      default: false,
    },

    maxAllowed: {
      type: Number,
      required: false,
      default: 10,
    },

    accept: {
      type: String,
      default: null,
    },

    uploadAuto: {
      type: Boolean,
      required: false,
      default: true,
    },

    refForm: {
      type: String,
      required: true,
      default: 'upload',
    },

    extensions: {
      type: String,
      required: false,
      default: 'gif,jpg,jpeg,png,pdf,doc,docx,xls,xlsx,odt,csv',
    },

    hideLog: {
      type: Boolean,
      required: false,
      default: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    filesObject: [],
    filesArray: [],
    dropdirectory: true,
    addindex: true,
    // extensions: ['gif', 'jpg', 'jpeg','png', 'webp'],
    // extensions: /\.(gif|jpe?g|png|webp)$/i,
    autoCompress: 1024 * 1024,
    threads: 3,
    loading: false,
    headers: {},
    tableHeaders: [
      {
        text: '',
        align: 'center',
        value: 'thumb',
      },
      {
        text: 'Nom',
        align: 'left',
        value: 'name',
      },
      {
        text: 'Taille',
        align: 'left',
        value: 'size',
      },
      {
        text: 'Statut',
        align: 'left',
      },
    ],
  }),

  computed: {
    nb_headers() {
      return this.uploadAuto ? 4 : 5;
    },
    postaction() {
      return this.url;
    },
  },

  mounted() {
    // console.log('refform', this.refForm)
    // console.log('arrayprops:',this.arrayFiles)
    // console.log('refForm:', this.refForm)
    // console.log('multiple:',this.isMultiple)
    if (JSON.parse(localStorage.getItem('user'))) {
      let token = JSON.parse(localStorage.getItem('user')).session;
      this.headers = { 'X-CUSTOM-AUTH-TOKEN': token };
    }
  },

  methods: {
    inputUpdate(files) {
      // console.log('add file to UPLOAD',files)
      // files[0].fileObject = false //le fichier ne part pas !
      this.arrayFiles = files;
      // this.$store.commit('upload', files)
      // console.log(this.files)
      // console.log('upload val:',this.$refs[this.refForm].upload)
      this.loading = true;
    },

    remove(file) {
      this.$refs[this.refForm].remove(file);
    },

    /**
     * Has changed
     * @param  Object|undefined   newFile   Read only
     * @param  Object|undefined   oldFile   Read only
     * @return undefined
     */
    inputFile(newFile, oldFile) {
      // console.log('Upload auto?',this.uploadAuto)
      // console.log('newFile', newFile)
      // console.log('oldFile', oldFile)

      // Upload error
      if (newFile.error && !oldFile.error) {
        console.log('error', newFile.error, newFile);
        if (newFile.error === 'extension') {
          this.snack({
            position: 'bottom',
            align: 'center',
            color: 'error',
            text: 'Extension non autorisée : ' + newFile.type,
          });
        } else {
          this.snack({
            position: 'bottom',
            align: 'center',
            color: 'error',
            text: 'Envoi du fichier impossible : ' + newFile.error,
          });
        }
      }

      // Uploaded successfully
      if (newFile.success && !oldFile.success) {
        // console.log('success', newFile.success, newFile)
        // console.log('envoi OK, Token:', newFile.response.token)
        let data = {};
        // console.log('form:',this.refForm)
        data[this.refForm] = newFile.response.token;
        this.filesObject.push(data);
        // console.log('fileobject:',this.filesObject)
        this.$emit('fileUploaded', this.filesObject);
      }
      this.loading = false;
      // Automatically activate upload
      if (
        Boolean(newFile) !== Boolean(oldFile) ||
        oldFile.error !== newFile.error
      ) {
        if (this.uploadAuto && !this.$refs[this.refForm].active) {
          this.$refs[this.refForm].active = true;
          // console.log('RESPONSE:',newFile)
        }
      }
    },

    /**
     * Pretreatment
     * @param  Object|undefined   newFile   Read and write
     * @param  Object|undefined   oldFile   Read only
     * @param  Function           prevent   Prevent changing
     * @return undefined
     */
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Test before adding the file
        // Filter system files or hide files
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }
        // Filter php html js exe bat file
        if (/\.(php5?|html?|jsx?|exe?|bat?)$/i.test(newFile.name)) {
          return prevent();
        }
        // Filter non-image file
        // if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
        //     return prevent()
        // }

        // Automatic compression
        // if (newFile.file && newFile.type.substr(0, 6) === 'image/' && this.autoCompress > 0 && this.autoCompress < newFile.size) {
        //     newFile.error = 'compression'
        //     const imageCompressor = new ImageCompressor(null, {
        //         convertSize: Infinity,
        //         maxWidth: 512,
        //         maxHeight: 512,
        //     })
        //     imageCompressor.compress(newFile.file)
        //         .then((file) => {
        //             this.$refs.upload.update(newFile, { error: '', file, size: file.size, type: file.type })
        //         })
        //         .catch((err) => {
        //             this.$refs.upload.update(newFile, { error: err.message || 'compress' })
        //         })
        // }
      }

      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        // Create a blob field
        newFile.blob = '';
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }

        // Thumbnails
        newFile.thumb = '';
        if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
          newFile.thumb = newFile.blob;
        }
      }

      if (newFile && oldFile) {
        // Update file
        // Increase the version number
        if (!newFile.version) {
          newFile.version = 0;
        }
        newFile.version++;
      }

      if (!newFile && oldFile) {
        // Remove file
        // Refused to remove the file
        // return prevent()
      }
    },
  },
};
</script>

<style>
#wrapper_uploadField.droppable {
  border: 3px dashed #4caf50;
  padding: 25px;
}
/* #wrapper_list ul{
} */
#wrapper_list ul li button.v-btn--small {
  height: 20px;
  width: 20px;
}
.tablefile th:first-child,
.tablefile tr td:first-child {
  padding: 0 4px;
}
.tablefile td,
.tablefile th {
  padding: 0 5px;
}
.uploader .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}
.uploader .drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
.uploader .progress {
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.uploader .progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}
.uploader .progress-bar {
  height: 1rem;
  line-height: 1rem;
  color: #fff;
  background-color: #4caf50;
  transition: width 0.6s ease;
}

@-webkit-keyframes argh-my-eyes {
  0% {
    background-color: rgb(7, 65, 133);
  }
  49% {
    background-color: rgb(20, 174, 221);
  }
  50% {
    background-color: rgb(4, 74, 153);
    width: 100%;
  }
  99% {
    background-color: rgb(14, 201, 226);
  }
  100% {
    background-color: rgb(12, 102, 219);
  }
}
@-moz-keyframes argh-my-eyes {
  0% {
    background-color: rgb(7, 65, 133);
  }
  49% {
    background-color: rgb(20, 174, 221);
  }
  50% {
    background-color: rgb(4, 74, 153);
    width: 100%;
  }
  99% {
    background-color: rgb(14, 201, 226);
  }
  100% {
    background-color: rgb(12, 102, 219);
  }
}
@keyframes argh-my-eyes {
  0% {
    background-color: rgb(7, 65, 133);
  }
  49% {
    background-color: rgb(20, 174, 221);
  }
  50% {
    background-color: rgb(4, 74, 153);
    width: 100%;
  }
  99% {
    background-color: rgb(14, 201, 226);
  }
  100% {
    background-color: rgb(12, 102, 219);
  }
}
.sendFile {
  -webkit-animation: argh-my-eyes 2s infinite;
  -moz-animation: argh-my-eyes 2s infinite;
  animation: argh-my-eyes 2s infinite;
}
.sendFile.v-btn--disabled {
  -webkit-animation: inherit;
  -moz-animation: inherit;
  animation: inherit;
}
</style>
