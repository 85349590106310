import { ContactService } from '@/store/services/ContactService'
import handleApiError from '@/helpers/handleApiError'

export default {
  get_contacts (context) {
    return new Promise((resolve, reject) => {
      ContactService.get_contacts()
        .then(response => {
          var data = response.data
          context.commit('SET_CONTACTS', data)
          resolve(data)
        })
        .catch(error => {
          handleApiError(error, reject)
        })
    })
  },
  get_contact (context, id) {
    return new Promise((resolve, reject) => {
      ContactService.get_contact()
        .then(response => {
          var data = response.data
          context.commit('SET_CONTACT', data)
          resolve(data)
        })
        .catch(error => {
          handleApiError(error, reject)
        })
    })
  },
  create_contact (context, contacts) {
    return new Promise((resolve, reject) => {
      ContactService.create_contact(contacts)
        .then(response => {
          var data = response.data
          context.commit('ADD_CONTACT', data)
          resolve(data)
        })
        .catch(error => {
          handleApiError(error, reject)
        })
    })
  }
}
