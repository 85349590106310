<template>
  <div class="pa-2">
    <h3
      v-if="title"
      class="pt-1 mb-2 text-xs-left title font-weight-regular text-uppercase"
    >
      {{ title }}
    </h3>
    <div class="mt-3">
      <slot />
    </div>
  </div>
  </div>
</template>
<script>
export default {
  name: 'TabSection',
  props: {
    title: {
      type: String,
      required: false
    }
  }
}
</script>
<style>

</style>
