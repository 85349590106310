<template>
  <div :class="['empty-slate grey lighten-5 pa-3', small ? 'small' : '']">
    <v-layout column justify-space-around>
      <v-flex class="pa-1">
        <v-icon x-large>
          {{ getIcon }}
        </v-icon>
      </v-flex>
      <v-flex>
        <h2 class="title font-weight-light">
          {{ title }}
        </h2>
      </v-flex>
      <v-flex class="pa-2">
        <p>{{ description || '' }}</p>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  name: 'EmptySlate',
  props: {
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
      default: 'Aucune donnée',
    },
    icon: {
      type: String,
      required: false,
      default: 'cancel',
    },
  },
  computed: {
    getIcon() {
      return this.icon || 'empty-set';
    },
  },
};
</script>
<style scoped>
.empty-slate {
  min-height: 50vh;
}
.empty-slate.small {
  min-height: 10vh;
}
</style>
