import { HTTP } from '@/plugins/axios'
import qs from 'qs'

const urlGroup = '/uatValidations'

export const UatValidationService = {
  get_user_acceptance_test_uat_validations (userAcceptanceTestsId) {
    return HTTP.get(`${urlGroup}/userAcceptanceTest/${userAcceptanceTestsId}`)
  },
  get_uat_validations_by_id (uatValidationsId) {
    return HTTP.get(`${urlGroup}/${uatValidationsId}`)
  },
  delete_uat_validations_by_id (uatValidationsId) {
    return HTTP.delete(`${urlGroup}/${uatValidationsId}`)
  },
  update_uat_validations (uatValidations) {
    return HTTP.put(`${urlGroup}/${uatValidations.id}`, qs.stringify(uatValidations))
  },
  create_uat_validations (uatValidations) {
    return HTTP.post(`${urlGroup}`, qs.stringify(uatValidations))
  }
}
