<template>
  <v-toolbar
    id="core-toolbar"
    flat
    prominent
    style="background: #eee;"
  >
    <div class="v-toolbar-title">
      <v-toolbar-title class="tertiary--text font-weight-light">
        <v-btn
          v-if="responsive"
          class="default v-btn--simple"
          dark
          icon
          @click.stop="onClickBtn"
        >
          <v-icon medium>
            mdi-view-list
          </v-icon>
        </v-btn>
        <h1
          class="d-inline-flex headline font-weight-light"
          v-html="title"
        />
        <h2
          v-if="getCustomerName"
          class="title d-inline-flex ml-3 font-weight-bold"
        >
          {{ getCustomerName }}
        </h2>
      </v-toolbar-title>
    </div>

    <v-spacer />
    <v-toolbar-items>
      <v-flex
        align-center
        layout
        py-2
      >
        <!-- to="/user-profile" -->
        <!-- <router-link
          v-ripple
          class="toolbar-items"
          to="/dashboard"
        >
          <v-icon
            color="tertiary"
            medium
          >
            mdi-account
          </v-icon>
        </router-link> -->

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <router-link
              v-ripple
              class="toolbar-items"
              to="/logout"
              v-bind="attrs"
            >
              <v-icon
                color="tertiary"
                medium
                v-on="on"
              >
                mdi-logout
              </v-icon>
            </router-link>
          </template>
          <span>Déconnexion</span>
        </v-tooltip>
      </v-flex>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'

export default {
  data: () => ({
    title: 'Tableau de bord',
    responsive: false,
    responsiveInput: false
  }),

  computed: {
    ...mapGetters({ customer: 'user/getCustomer' }),

    getCustomerName () {
      // console.log(this.$route.path)
      if (this.$route.path === '/dashboard') {
        let cust = this.customer()
        return cust.name
      } else {
        return ''
      }
    }
  },

  watch: {
    $route (val) {
      // console.log('watch:',val)
      this.title = val.name
    }
  },

  created () {
    // window.addEventListener('beforeunload', this.logout
    // window.onbeforeunload = function () {
    //   console.log('LOGOUT')
    //   return 'handle your events or msgs here'
    // }
  },

  mounted () {
    // console.log(this.$route)
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },

  methods: {
    ...mapActions('log', ['log_logout']),
    ...mapMutations('app', ['setDrawer', 'toggleDrawer']),

    onClickBtn () {
      this.setDrawer(!this.$store.state.app.drawer)
    },

    logout (e) {
      console.log('LOGOUT')

      this.log_logout()
        .finally(() => {
          // remove localstorage user then redirect to login
          localStorage.removeItem('user')
          localStorage.removeItem('session')
        })
    },

    onResponsiveInverted () {
      if (window.innerWidth < 991) {
        this.responsive = true
        this.responsiveInput = false
      } else {
        this.responsive = false
        this.responsiveInput = true
      }
    }
  }
}
</script>

<style>
#core-toolbar a {
  text-decoration: none;
}
</style>
