<template>
  <v-tooltip top color="black">
    <v-btn
      slot="activator"
      flat
      icon
      :outline="outline"
      :color="color"
      :href="href"
      :disabled="disabled"
      target="_blank"
    >
      <v-icon v-if="icon" :color="iconcolor">
        {{ icon }}
      </v-icon>
    </v-btn>
    <span :class="color + '--text'">
      {{ label }}
    </span>
  </v-tooltip>
</template>
<script>
export default {
  name: 'DownloadButton',

  props: {
    label: {
      type: String,
      required: true,
    },

    href: {
      type: String,
      default: null,
    },

    color: {
      type: String,
      default: 'primary',
    },

    icon: {
      type: String,
      default: null,
    },

    iconcolor: {
      type: String,
      default: 'info',
    },

    outline: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style></style>
