<template>
  <v-card-title
    :class="[bgColor, 'lighten-3 pa-0 ma-0']"
    style="flex-direction: column; position: relative; align-items: stretch"
  >
    <v-layout row justify-space-between class="px-3 py-1">
      <v-flex xs12 md6>
        <v-layout align-center row fill-height>
          <v-avatar size="60" class="mr-1">
            <v-icon x-large>
              {{ icon }}
            </v-icon>
          </v-avatar>
          <div class="text-xs-left">
            <v-tooltip top>
              <h1 slot="activator" class="text-truncate py-0">
                {{ name.toUpperCase() }}
              </h1>
              <span>{{ name.toUpperCase() }}</span>
            </v-tooltip>
            <Identifier v-if="id" :id="id" :prefix="prefix" />
            <v-chip v-if="outline" label outline color="red">
              {{ outline }}
            </v-chip>
          </div>
        </v-layout>
      </v-flex>

      <v-flex v-if="link && link.type !== 'Client'" xs12 md6>
        <v-layout justify-end align-end>
          <v-flex class="text-xs-right">
            <h2 class="text-uppercase text-truncate">
              {{ link && link.type }}
            </h2>
            <EntityLink
              v-if="link && link.name"
              :icon="link.icon"
              :href="link.href"
            >
              {{ link.name.toUpperCase() }}
            </EntityLink>
          </v-flex>
          <!-- <v-flex class="text-md-right" v-if="showLink">
            <v-btn flat :to="`/registries/${registry.id}/view`"><v-icon>assignment</v-icon>Voir le registre {{registry.name}}
            </v-btn>
          </v-flex> -->
        </v-layout>
      </v-flex>
      <v-flex v-if="isAdmin && link && link.type === 'Client'" xs12 md6>
        <v-layout justify-end align-end>
          <v-flex class="text-xs-right">
            <h2 class="text-uppercase text-truncate">
              {{ link && link.type }}
            </h2>
            <EntityLink
              v-if="link && link.name"
              :icon="link.icon"
              :href="link.href"
            >
              {{ link.name.toUpperCase() }}
            </EntityLink>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card-title>
</template>
<script>
import Identifier from './Identifier';
import EntityLink from './EntityLink';
import RoleMixin from '@/mixins/RoleMixin';

export default {
  name: 'CardHeader',

  components: {
    Identifier,
    EntityLink,
  },

  mixins: [RoleMixin],

  props: {
    icon: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    prefix: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    outline: {
      type: String,
      default: null,
    },
    link: {
      type: Object,
      default: null,
    },
    bgColor: {
      type: String,
      default: 'grey',
    },
  },
};
</script>
<style scoped>
h1 {
  max-width: 300px;
}
.avatar {
  border-radius: 0 !important;
  /* -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%); */
  height: 100px !important;
  width: 100px !important;
}
</style>
