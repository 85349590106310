import { HTTP } from '@/plugins/axios'
import qs from 'qs'

const urlGroup = '/customers'

export const CustomerService = {
  get_customers () {
    return HTTP.get(`${urlGroup}`)
  },
  get_customer (customersId) {
    return HTTP.get(`${urlGroup}/${customersId}`)
  },
  delete_customer_by_id (customersId) {
    return HTTP.delete(`${urlGroup}/${customersId}`)
  },
  update_customer (customers) {
    return HTTP.put(`${urlGroup}/${customers.id}`, qs.stringify(customers))
  },
  create_customer (customers) {
    return HTTP.post(`${urlGroup}`, qs.stringify(customers))
  }
}
