var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',[_c('v-card',[_c('v-card-title',[_vm._v(" Liste des projets "),_c('v-spacer'),(_vm.canSearch)?_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Recherche rapide","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"pagination":_vm.pagination,"search":_vm.search},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"headerCell",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"font-weight-light text--darken-3"},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"items",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-xs-left pointer",on:{"click":function($event){return _vm.$emit('click:row', item.id)}}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',{staticClass:"text-xs-left pointer",on:{"click":function($event){return _vm.$emit('click:row', item.id)}}},[_vm._v(" "+_vm._s(item.nb_sprints > 0 ? ((item.nb_sprints_done) + " / " + (item.nb_sprints)) : 'Aucun')+" ")]),_c('td',{staticClass:"text-xs-left pointer",on:{"click":function($event){return _vm.$emit('click:row', item.id)}}},[_c('ui-ProgressCircle',{attrs:{"done":item.nb_sprints_done,"total":item.nb_sprints}})],1),_c('td',{staticClass:"text-xs-left pointer",on:{"click":function($event){return _vm.$emit('click:row', item.id)}}},[_vm._v(" "+_vm._s(new Date(item.created_at.replace(/ /g, 'T')).toLocaleDateString( 'fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', } ))+" ")]),(_vm.headers.filter(function (e) { return e.text === 'Action'; }).length)?_c('td',{staticClass:"text-xs-left pointer'"},[_c('v-btn',{staticClass:"mr-1",attrs:{"disabled":!_vm.isAdmin &&
                !_vm.datas
                  .map(function (item) { return item.users_id; })
                  .includes(_vm.getConnectedUser.id),"text":"","icon":""},on:{"click":function($event){return _vm.$emit('click:edit', item.id)}}},[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(" mdi-circle-edit-outline ")])],1),_c('v-btn',{staticClass:"ml-1",attrs:{"disabled":!_vm.isAdmin &&
                !_vm.datas
                  .map(function (item) { return item.users_id; })
                  .includes(_vm.getConnectedUser.id),"text":"","icon":""},on:{"click":function($event){return _vm.$emit('click:delete', item.id)}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-delete-outline ")])],1)],1):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }