import { UserService } from '@/store/services/UserService'
import handleApiError from '@/helpers/handleApiError'
import Router from '@/router/'

export default {

  user_login (context, credentials) {
    return new Promise((resolve, reject) => {
      UserService.user_login(credentials)
        .then(response => {
          console.log('LOGIN OK', response)
          var user = response.data
          // create session and user in the localstorage
          localStorage.setItem('session', user.session_token)
          localStorage.setItem('user', JSON.stringify(user))
          // set current user in store
          context.commit('SET_USER', user)
          // redirect to dashboard
          Router.push({
            path: 'dashboard'
          })
          resolve(user)
        })
        .catch(error => {
          handleApiError(error, reject)
        })
    })
  },

  get_user (context, usersId) {
    return new Promise((resolve, reject) => {
      UserService.get_user(usersId)
        .then(response => {
          var data = response.data
          context.commit('SET_USER', data)
          resolve(data)
        })
        .catch(error => {
          handleApiError(error, reject)
        })
    })
  },

  get_users (context) {
    return new Promise((resolve, reject) => {
      UserService.get_users()
        .then(response => {
          var data = response.data
          context.commit('SET_USERS', data)
          resolve(data)
        })
        .catch(error => {
          handleApiError(error, reject)
        })
    })
  },
  delete_user_by_id (context, usersId) {
    return new Promise((resolve, reject) => {
      UserService.delete_user_by_id(usersId)
        .then(response => {
          var data = response.data
          context.commit('ACTIVATE_USER', usersId)
          resolve(data)
        })
        .catch(error => {
          handleApiError(error, reject)
        })
    })
  },
  update_user (context, users) {
    return new Promise((resolve, reject) => {
      UserService.update_user(users)
        .then(response => {
          var data = response.data
          context.commit('ADD_USER', users)
          resolve(data)
        })
        .catch(error => {
          handleApiError(error, reject)
        })
    })
  },
  create_user (context, users) {
    return new Promise((resolve, reject) => {
      UserService.create_user(users)
        .then(response => {
          var data = response.data
          context.commit('ADD_USER', users)
          resolve(data)
        })
        .catch(error => {
          handleApiError(error, reject)
        })
    })
  }
}
