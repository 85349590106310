import Vue from 'vue'
import Vuetify from 'vuetify'
/* validation library */
import Vuelidate from 'vuelidate'
/* style */
import theme from './theme'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
/* locale */
import fr from 'vuetify/es5/locale/fr'
import _ from 'lodash'

Vue.prototype._ = _
Vue.use(Vuelidate)
Vue.use(Vuetify, {
  iconfont: 'mdi',
  theme,
  lang: {
    locales: { fr },
    current: 'fr'
  }
})
