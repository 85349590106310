import { HTTP } from '@/plugins/axios'
import qs from 'qs'

const urlGroup = '/users'

export const UserService = {
  user_login (credentials) {
    return HTTP.post(`/login`, qs.stringify(credentials))
  },
  get_user (usersId) {
    return HTTP.get(`${urlGroup}/${usersId}`)
  },
  get_users () {
    return HTTP.get(`${urlGroup}`)
  },
  delete_user_by_id (usersId) {
    return HTTP.delete(`${urlGroup}/${usersId}`)
  },
  update_user (users) {
    return HTTP.put(`${urlGroup}/${users.id}`, qs.stringify(users))
  },
  create_user (users) {
    return HTTP.post(`${urlGroup}`, qs.stringify(users))
  }
}
