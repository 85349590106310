<template>
  <v-flex wrap>
    <v-icon v-if="state == 'VALIDATED'" color="success">
      <!-- progress 2-->
      mdi-check-circle
    </v-icon>
    <v-icon v-else-if="state == 'VALIDATED_WITH_COMMENT'" color="warning">
      <!-- progress 1 -->
      mdi-alert-circle
    </v-icon>
    <v-icon v-else-if="state == 'QUESTION'" color="info">
      <!-- progress 3 -->
      mdi-alarm-light
    </v-icon>
    <v-icon v-else color="error">
      <!-- progress 0 -->
      mdi-close-circle
    </v-icon>
  </v-flex>
</template>
<script>
export default {
  name: 'UatValidationProgressCircle',

  props: {
    state: {
      type: String,
      required: false,
      default: 'FAILED',
    },
  },
};
</script>
<style></style>
