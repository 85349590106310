<template>
  <ui-FileViewer
    :files="localFiles"
    :manage="true"
    @fileDeleted="fileDelete"
  >
    <template v-slot:button>
      <ui-Upload
        :ref-form="uuid"
        :is-multiple="false"
        :can-drop="false"
        :hide-log="false"
        :extensions="extensions"
        :url="url"
        :disabled="disabled"
        @fileUploaded="inputFile"
      />
      <!-- SNACKBAR -->
      <ui-SnackBarV2
        :text="text"
        :color="color"
        :bottom="bottom"
        :top="top"
        :right="right"
        :left="left"
        :snackbar="snackbar"
      />
    </template>
  </ui-FileViewer>
</template>
<script>
import { ENV } from '@/plugins/constant'
import { mapActions } from 'vuex'
import ErrorDialogMixin from '@/mixins/ErrorDialogMixin'
import SnackBarMixin from '@/mixins/SnackBarMixin'

export default {
  name: 'FileWidget',

  mixins: [SnackBarMixin, ErrorDialogMixin],

  props: {
    files: {
      type: Array,
      required: false,
      default: () => []
    },

    url: {
      type: String,
      required: false,
      default: `${ENV}/files`
    },

    attribute: {
      type: String,
      required: false,
      default: null
    },

    pricelist: {
      type: Boolean,
      required: false,
      default: false
    },

    extensions: {
      type: String,
      required: false,
      default: 'gif,jpg,jpeg,png,pdf,doc,docx,xls,xlsx,odt,csv'
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    }

  },

  data: () => ({
    uuid: `${Date.now()}`,
    localFiles: []
  }),

  // computed: {
  // uuid(){
  //   let now = Date.now()
  //   return now.toString()
  // }fileUploaded
  // },

  // watch:{
  //   disabled () {
  //     console.log('disab:', this.disabled)
  //   }
  // },

  mounted () {
    this.localFiles = this.files
    // console.log('mounted:', this.id)
    // console.log('mounted disabled:', this.disabled)
  },

  methods: {
    ...mapActions('file', ['attach_file', 'attach_pricelist_file']),

    inputFile (files) {
      let filesToken = []
      files.forEach(file => {
        filesToken.push(file[this.uuid])
      })
      this.$emit('fileUploaded', filesToken)
    },

    fileDelete (file) {
      console.log('delete file', file)
      this.localFiles = this.localFiles.filter(localFile => {
        return file.id !== localFile.id
      })
    }
  }
}
</script>
<style>
</style>
