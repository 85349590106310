import { set } from '@/utils/vuex'

export default {
  SET_PROJECT_SPRINTS: set('projectSprints'),
  SET_SPRINTS: set('sprints'),
  SET_SPRINT: set('sprint'),
  DELETE_SPRINT: (state, sprintsId) => {
    state.projectSprints = state.projectSprints.filter(item => { return item.id !== sprintsId })
    state.sprints = state.sprints.filter(item => { return item.id !== sprintsId })
    state.sprint = state.sprint.id !== sprintsId ? state.sprint : {}
  },
  ADD_SPRINT: (state, sprints) => {
    let index = state.projectSprints.map(item => item.id).indexOf(sprints.id)
    index >= 0 ? state.projectSprints[index] = sprints : state.projectSprints.push(sprints)
    index = state.sprints.map(item => item.id).indexOf(sprints.id)
    index >= 0 ? state.sprints[index] = sprints : state.sprints.push(sprints)
    state.sprint = sprints
  }
}
