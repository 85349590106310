import { UserAcceptanceTestService } from '@/store/services/UserAcceptanceTestService'
import handleApiError from '@/helpers/handleApiError'

export default {
  get_user_story_user_acceptance_tests (context, userStoriesId) {
    return new Promise((resolve, reject) => {
      UserAcceptanceTestService.get_user_story_user_acceptance_tests(userStoriesId)
        .then(response => {
          var data = response.data
          context.commit('SET_USER_STORIES_USER_ACCEPTANCE_TESTS', data)
          resolve(data)
        })
        .catch(error => {
          handleApiError(error, reject)
        })
    })
  },
  get_user_acceptance_tests_by_id (context, userAcceptanceTestsId) {
    return new Promise((resolve, reject) => {
      UserAcceptanceTestService.get_user_acceptance_tests_by_id(userAcceptanceTestsId)
        .then(response => {
          var data = response.data
          context.commit('SET_USER_ACCEPTANCE_TEST', data)
          resolve(data)
        })
        .catch(error => {
          handleApiError(error, reject)
        })
    })
  },
  delete_user_acceptance_tests_by_id (context, userAcceptanceTestsId) {
    return new Promise((resolve, reject) => {
      UserAcceptanceTestService.delete_user_acceptance_tests_by_id(userAcceptanceTestsId)
        .then(response => {
          var data = response.data
          context.commit('DELETE_USER_ACCEPTANCE_TEST', userAcceptanceTestsId)
          resolve(data)
        })
        .catch(error => {
          handleApiError(error, reject)
        })
    })
  },
  update_user_acceptance_tests (context, userAcceptanceTests) {
    return new Promise((resolve, reject) => {
      UserAcceptanceTestService.update_user_acceptance_tests(userAcceptanceTests)
        .then(response => {
          var data = response.data
          context.commit('ADD_USER_ACCEPTANCE_TEST', userAcceptanceTests)
          resolve(data)
        })
        .catch(error => {
          handleApiError(error, reject)
        })
    })
  },
  create_user_acceptance_tests (context, userAcceptanceTests) {
    return new Promise((resolve, reject) => {
      UserAcceptanceTestService.create_user_acceptance_tests(userAcceptanceTests)
        .then(response => {
          var data = response.data
          context.commit('ADD_USER_ACCEPTANCE_TEST', userAcceptanceTests)
          resolve(data)
        })
        .catch(error => {
          handleApiError(error, reject)
        })
    })
  }
}
