<template>
  <v-navigation-drawer
    id="app-drawer"
    v-model="inputValue"
    app
    dark
    floating
    persistent
    mobile-break-point="991"
    width="260"
  >
    <v-flex
      primary
      style="height:100%;"
    >
      <v-layout
        tag="v-list"
        column
      >
        <img
          class="drawer-logo"
          :src="logo"
          :height="120"
          :width="120"
        >
        <h2 style="text-align: center;">
          Manager
        </h2>
        <v-divider />
        <v-list-tile
          v-for="(link, i) in links"
          :key="i"
          :to="link.to"
          :active-class="color"
          avatar
          class="v-list-item"
        >
          <v-list-tile-action>
            <v-icon
              large
            >
              {{ link.icon }}
            </v-icon>
          </v-list-tile-action>
          <v-list-tile-title
            v-text="link.text"
          />
        </v-list-tile>

        <!-- Admin menu -->
        <br v-if="isAdmin">
        <v-divider v-if="isAdmin" />
        <v-flex v-if="isAdmin">
          <v-list-tile
            v-for="(link, i) in adminLinks"
            :key="'admin'+i"
            :to="link.to"
            :active-class="color"
            avatar
            class="v-list-item"
          >
            <v-list-tile-action>
              <v-icon
                large
              >
                {{ link.icon }}
              </v-icon>
            </v-list-tile-action>
            <v-list-tile-title
              v-text="link.text"
            />
          </v-list-tile>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapMutations, mapState, mapGetters } from 'vuex'

export default {
  data: () => ({
    links: [
      {
        to: '/dashboard',
        icon: 'mdi-view-dashboard',
        text: 'Mes projets'
      }
    ],
    adminLinks: [
      {
        to: '/admin/dashboard',
        icon: 'mdi-view-dashboard',
        text: 'Tous les projets'
      },
      {
        to: '/customer',
        icon: 'mdi-clipboard-account',
        text: 'Clients'
      },
      {
        to: '/user',
        icon: 'mdi-account-group',
        text: 'Utilisateurs'
      }
    ],
    responsive: false
  }),

  computed: {
    ...mapState('app', ['color']),
    ...mapGetters('user', ['getUser']),

    logo () {
      return this.$store.state.app.logo
    },

    inputValue: {
      get () {
        return this.$store.state.app.drawer
      },
      set (val) {
        this.setDrawer(val)
      }
    },

    items () {
      return this.$t('Layout.View.items')
    }
  },

  mounted () {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },

  methods: {
    ...mapMutations('app', ['setDrawer', 'toggleDrawer']),
    onResponsiveInverted () {
      if (window.innerWidth < 991) {
        this.responsive = true
      } else {
        this.responsive = false
      }
    }
  }
}
</script>

<style lang="scss">
  #app-drawer {
    .v-list__tile__title{
      font-weight:400 !important;
      font-size:1em !important
    }
    .v-list__tile {
      border-radius: 4px;

      &--buy {
        margin-top: auto;
        margin-bottom: 17px;
      }

    }
    .v-list-item{
      width: 93%;
    }
    div.v-responsive.v-image > div.v-responsive__content {
      overflow-y: auto;
    }
  }
</style>
