import { set } from '@/utils/vuex'

export default {
  SET_CUSTOMER: set('customer'),
  SET_CUSTOMERS: set('customers'),
  SET_CUSTOMER_SCOPE (state, customerId) {
    localStorage.setItem('customer_scope', JSON.stringify(customerId))
  },
  DELETE_CUSTOMER: (state, customersId) => {
    state.customers = state.customers.filter(item => { return item.id !== customersId })
    state.customer = state.customer.id !== customersId ? state.customer : {}
  },
  ADD_CUSTOMER: (state, customers) => {
    let index = state.customers.map(item => item.id).indexOf(customers.id)
    index >= 0 ? state.customers[index] = customers : state.customers.push(customers)
    state.customer = customers
  }
}
