<template>
  <v-flex v-if="isAdmin && formType != ''" wrap ma-5>
    <v-autocomplete
      v-model="userAcceptanceTest.projects_id"
      :items="projects"
      item-text="name"
      item-value="id"
      label="Projet"
      :disabled="loading"
      @change="getDataProjectSprints($event)"
    />
    <v-autocomplete
      v-model="userAcceptanceTest.sprints_id"
      :items="sprints"
      item-text="name"
      item-value="id"
      label="Sprint"
      :disabled="loading || !sprints.length"
      @change="getDataSprintUserStories($event)"
    />
    <v-autocomplete
      v-model="userAcceptanceTest.user_stories_id"
      :items="userStories"
      item-text="name"
      item-value="id"
      label="User Story"
      :error-messages="userStoriesErrors"
      :disabled="loading || !userStories.length"
    />
    <v-flex v-if="selectedUserStories && selectedUserStories.comment" wrap>
      <h4>Description de la User Story</h4>
      <p style="white-space: pre-line" v-html="selectedUserStories.comment" />
    </v-flex>
    <v-text-field
      v-model="userAcceptanceTest.name"
      label="Nom de la user acceptance test"
      :error-messages="nameErrors"
      :disabled="loading"
    />
    <v-textarea
      v-model="userAcceptanceTest.comment"
      label="Commentaire de la user acceptance test"
      clear-icon="mdi-delete-outline"
      :disabled="loading"
      auto-grow
      clearable
      counter
    />
    <v-flex>
      <v-flex left>
        <v-btn center class="quaternary" @click="$router.go(-1)">
          <v-icon>mdi-arrow-left</v-icon>
          <v-flex ml-2 pa-0>
            Retour
          </v-flex>
        </v-btn>
      </v-flex>
      <v-flex right>
        <v-btn
          :disabled="!isAdmin || $v.$invalid || loading"
          center
          class="success"
          @click="save()"
        >
          <v-icon>mdi-content-save-outline</v-icon>
          <v-flex ml-2 pa-0>
            Sauvegarder
          </v-flex>
        </v-btn>
      </v-flex>
    </v-flex>
  </v-flex>
  <v-flex v-else wrap ma-5>
    Aucun formulaire
  </v-flex>
</template>

<script>
import { required, numeric } from 'vuelidate/lib/validators';
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: 'UserAcceptanceTestForm',

  props: {
    userAcceptanceTest: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    formType: '',
    formTypes: {
      edit: 'EDIT',
      create: 'CREATE',
    },
    init: false,
  }),

  validations: {
    userAcceptanceTest: {
      name: {
        required,
      },
      user_stories_id: {
        required,
        number: {
          numeric,
        },
      },
    },
  },

  computed: {
    ...mapGetters('project', ['getProjects']),
    ...mapGetters('sprint', ['getProjectSprints']),
    ...mapGetters('userStory', ['getSprintUserStories']),
    ...mapGetters('app', ['getDashboardIds']),

    defaultUserStoriesId() {
      return this.getDashboardIds().userStory;
    },

    defaultSprintsId() {
      return this.getDashboardIds().sprint;
    },

    defaultProjectsId() {
      return this.getDashboardIds().project;
    },

    userStories() {
      return this.getSprintUserStories();
    },

    selectedUserStories() {
      if (this.userStories && this.userAcceptanceTest.user_stories_id) {
        return this.userStories.filter((item) => {
          return item.id == this.userAcceptanceTest.user_stories_id;
        })[0];
      } else if (this.userStories && this.getDashboardIds().userStory) {
        return this.userStories.filter((item) => {
          return item.id == this.getDashboardIds().userStory;
        })[0];
      } else {
        return null;
      }
    },

    sprints() {
      return this.getProjectSprints();
    },

    projects() {
      return this.getProjects();
    },

    nameErrors() {
      const errors = [];
      if (!this.$v.userAcceptanceTest.name.$dirty) return errors;
      !this.$v.userAcceptanceTest.name.required &&
        errors.push('Le nom est obligatoire');
      return errors;
    },

    userStoriesErrors() {
      const errors = [];
      if (!this.$v.userAcceptanceTest.user_stories_id.$dirty) return errors;
      !this.$v.userAcceptanceTest.user_stories_id.required &&
        errors.push('Le sprint est obligatoire');
      !this.$v.userAcceptanceTest.user_stories_id.number.numeric &&
        errors.push('Le sprint est obligatoire');
      return errors;
    },
  },

  mounted() {
    if (this.$route.params.id > 0) {
      this.formType = this.formTypes.edit;
      this.userAcceptanceTest.id = parseInt(
        this.userAcceptanceTest.id || this.$route.params.id
      );
    } else {
      this.formType = this.formTypes.create;
      if (this.defaultUserStoriesId > 0) {
        this.userAcceptanceTest.user_stories_id = parseInt(
          this.defaultUserStoriesId
        );
      }
      if (this.defaultProjectsId > 0) {
        this.userAcceptanceTest.projects_id = parseInt(this.defaultProjectsId);
      }
      if (this.defaultSprintsId > 0) {
        this.userAcceptanceTest.sprints_id = parseInt(this.defaultSprintsId);
      }
    }
    this.showLoader();
    this.get_projects()
      .then((response) => {
        console.log('Projects OK', response);
        if (this.userAcceptanceTest.projects_id > 0) {
          this.get_project_sprints(this.userAcceptanceTest.projects_id)
            .then((response) => {
              console.log('Sprints OK', response);
              if (this.userAcceptanceTest.sprints_id > 0) {
                this.get_sprint_user_stories(this.userAcceptanceTest.sprints_id)
                  .then((response) => {
                    console.log('User Stories OK', response);
                  })
                  .catch((error) => {
                    console.log('Erreur', error);
                    this.snack({
                      position: 'bottom',
                      align: 'center',
                      color: 'error',
                      text:
                        'Obtention des User Stories impossible : ' +
                        error.message,
                    });
                  })
                  .finally(() => {
                    this.init = true;
                  });
              }
            })
            .catch((error) => {
              console.log('Erreur', error);
              this.snack({
                position: 'bottom',
                align: 'center',
                color: 'error',
                text: 'Obtention des Sprints impossible : ' + error.message,
              });
            });
        }
      })
      .catch((error) => {
        console.log('Erreur', error);
        this.snack({
          position: 'bottom',
          align: 'center',
          color: 'error',
          text: 'Obtention des Projets impossible : ' + error.message,
        });
      })
      .finally(() => {
        this.showLoader();
      });
  },

  methods: {
    ...mapActions('project', ['get_projects']),
    ...mapActions('sprint', ['get_project_sprints']),
    ...mapActions('userStory', ['get_sprint_user_stories']),
    ...mapMutations('app', ['showLoader']),

    getDataProjectSprints(projectsId) {
      if (this.init) {
        this.showLoader();
        this.get_project_sprints(projectsId)
          .then((response) => {
            console.log('Sprints OK', response);
            if (this.init) {
              this.userAcceptanceTest.sprints_id = null;
            }
          })
          .catch((error) => {
            console.log('Erreur', error);
            this.snack({
              position: 'bottom',
              align: 'center',
              color: 'error',
              text: 'Obtention des Sprints impossible : ' + error.message,
            });
          })
          .finally(() => {
            this.showLoader();
          });
      }
    },

    getDataSprintUserStories(sprintsId) {
      if (this.init) {
        this.showLoader();
        this.get_sprint_user_stories(sprintsId)
          .then((response) => {
            console.log('User Stories OK', response);
            if (this.init) {
              this.userAcceptanceTest.user_stories_id = null;
            }
          })
          .catch((error) => {
            console.log('Erreur', error);
            this.snack({
              position: 'bottom',
              align: 'center',
              color: 'error',
              text: 'Obtention des User Stories impossible : ' + error.message,
            });
          })
          .finally(() => {
            this.showLoader();
          });
      }
    },

    save() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (
          this.userAcceptanceTest.id == null ||
          this.userAcceptanceTest.id <= 0
        ) {
          delete this.userAcceptanceTest.id;
        }
        this.$emit('save', this.userAcceptanceTest);
        if (this.formType === this.formTypes.create) {
          this.userAcceptanceTest = {};
        }
      }
    },
  },
};
</script>
