<template>
  <v-dialog
    v-model="show"
    max-width="350px"
  >
    <v-card>
      <v-card-title>
        <h2>
          <v-icon
            color="error"
            left
          >
            error
          </v-icon>
          {{ title }}
        </h2>
      </v-card-title>
      <v-card-text v-html="msg" />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ErrorDialog',
  props: {
    title: {
      type: String,
      required: false,
      default: 'Erreur'
    },
    msg: {
      type: String,
      required: true
    }
  },
  computed: {
    show: {
      get () {
        return this.$store.state.StoreUI.modalVisible
      },
      set (val) {
        this.$store.commit('HIDE_MODAL')
      }
    }
  }
}
</script>

<style></style>
