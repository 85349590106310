import { SprintService } from '@/store/services/SprintService';
import handleApiError from '@/helpers/handleApiError';

export default {
  get_sprints(context) {
    return new Promise((resolve, reject) => {
      SprintService.get_sprints()
        .then((response) => {
          var data = response.data;
          context.commit('SET_SPRINTS', data);
          resolve(data);
        })
        .catch((error) => {
          handleApiError(error, reject);
        });
    });
  },
  get_project_sprints(context, projectsId) {
    return new Promise((resolve, reject) => {
      SprintService.get_project_sprints(projectsId)
        .then((response) => {
          var data = response.data;
          context.commit('SET_PROJECT_SPRINTS', data);
          resolve(data);
        })
        .catch((error) => {
          handleApiError(error, reject);
        });
    });
  },
  get_sprint_by_id(context, sprintsId) {
    return new Promise((resolve, reject) => {
      SprintService.get_sprint_by_id(sprintsId)
        .then((response) => {
          var data = response.data;
          context.commit('SET_SPRINT', data);
          resolve(data);
        })
        .catch((error) => {
          handleApiError(error, reject);
        });
    });
  },
  delete_sprint_by_id(context, sprintsId) {
    return new Promise((resolve, reject) => {
      SprintService.delete_sprint_by_id(sprintsId)
        .then((response) => {
          var data = response.data;
          context.commit('DELETE_SPRINT', sprintsId);
          resolve(data);
        })
        .catch((error) => {
          handleApiError(error, reject);
        });
    });
  },
  update_sprint(context, sprints) {
    return new Promise((resolve, reject) => {
      SprintService.update_sprint(sprints)
        .then((response) => {
          var data = response.data;
          context.commit('ADD_SPRINT', sprints);
          resolve(data);
        })
        .catch((error) => {
          handleApiError(error, reject);
        });
    });
  },
  create_sprint(context, sprints) {
    return new Promise((resolve, reject) => {
      SprintService.create_sprint(sprints)
        .then((response) => {
          var data = response.data;
          context.commit('ADD_SPRINT', sprints);
          resolve(data);
        })
        .catch((error) => {
          handleApiError(error, reject);
        });
    });
  },
};
