<template>
  <v-flex v-if="formType != ''" wrap ma-5>
    <v-autocomplete
      v-model="uatValidation.projects_id"
      :items="projects"
      item-text="name"
      item-value="id"
      label="Projet"
      :disabled="loading || !isAdmin"
      @change="getDataProjectSprints($event)"
    />
    <v-autocomplete
      v-model="uatValidation.sprints_id"
      :items="sprints"
      item-text="name"
      item-value="id"
      label="Sprint"
      :disabled="loading || !sprints.length || !isAdmin"
      @change="getDataSprintUserStories($event)"
    />
    <v-autocomplete
      v-model="uatValidation.user_stories_id"
      :items="userStories"
      item-text="name"
      item-value="id"
      label="User Story"
      :disabled="loading || !userStories.length || !isAdmin"
      @change="getDataUserStoryUserAcceptanceTests($event)"
    />
    <v-flex v-if="selectedUserStories && selectedUserStories.comment" wrap>
      <h4>Description de la User Story</h4>
      <p style="white-space: pre-line" v-html="selectedUserStories.comment" />
    </v-flex>
    <v-autocomplete
      v-model="uatValidation.user_acceptance_tests_id"
      :items="userAcceptanceTests"
      item-text="name"
      item-value="id"
      label="User Acceptance Test"
      :error-messages="userAcceptanceTestsErrors"
      :disabled="loading || !userAcceptanceTests.length || !isAdmin"
      @change="getDataUserAcceptanceTestUATValidations($event)"
    />
    <v-flex
      v-if="selectedUserAcceptanceTest && selectedUserAcceptanceTest.comment"
      wrap
    >
      <h4>Description de la user acceptance test</h4>
      <p
        style="white-space: pre-line"
        v-html="selectedUserAcceptanceTest.comment"
      />
    </v-flex>
    <material-DataTableUatValidation
      :items="uatValidations"
      no-actions
      can-search
    />
    <!-- <v-flex wrap ma-2 elevation-2>
      <h5>
        <v-icon color="info">
          mdi-information
        </v-icon>
        Instructions
      </h5>
      <p>
        Afin de valider l'UAT vous devez cocher la case suivante. <br />
        Si vous avez des réserves, vous devez les noter dans la case
        'Commentaire'. <br />
        Si vous ne validez pas le test, vous ne devez pas cocher la casze, vous
        pourrez quand même indiquer vos réserves
      </p>
      <p v-if="isAdmin">
        En tant qu'administrateur, vous pouvez forcer la validation de l'UAT en
        cochant la case suivante.
      </p>
    </v-flex> -->
    <v-select
      v-model="uatValidation.state"
      mt-2
      :items="[...states, ...(isAdmin ? adminStates : [])]"
      :error-messages="stateErrors"
      label="État de l'UAT"
    ></v-select>

    <v-textarea
      v-model="uatValidation.comment"
      label="Commentaire"
      clear-icon="mdi-delete-outline"
      :disabled="loading"
      auto-grow
      clearable
      counter
    />
    <v-flex>
      <v-flex left>
        <v-btn center class="quaternary" @click="$router.go(-1)">
          <v-icon>mdi-arrow-left</v-icon>
          <v-flex ml-2 pa-0>
            Retour
          </v-flex>
        </v-btn>
      </v-flex>
      <v-flex right>
        <v-btn
          :disabled="$v.$invalid || loading"
          center
          class="success"
          @click="save()"
        >
          <v-icon>mdi-content-save-outline</v-icon>
          <v-flex ml-2 pa-0>
            Sauvegarder
          </v-flex>
        </v-btn>
      </v-flex>
    </v-flex>
  </v-flex>
  <v-flex v-else wrap ma-5>
    Aucun formulaire
  </v-flex>
</template>

<script>
import { required, numeric } from 'vuelidate/lib/validators';
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: 'UatValidationForm',

  props: {
    uatValidation: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    formType: '',
    formTypes: {
      edit: 'EDIT',
      create: 'CREATE',
    },
    init: false,
    states: [
      { value: 'VALIDATED', text: 'Je recette cette UAT' },
      {
        value: 'VALIDATED_WITH_COMMENT',
        text: 'Je recette cette UAT avec des réserves',
      },
      { value: 'FAILED', text: 'Je ne recette pas cette UAT' },
    ],
    adminStates: [
      { value: 'QUESTION', text: 'Je remet cette UAT en demande de recette' },
    ],
  }),

  validations: {
    uatValidation: {
      user_acceptance_tests_id: {
        required,
        number: {
          numeric,
        },
      },
      state: {
        required,
      },
    },
  },

  computed: {
    ...mapGetters('project', ['getProjects']),
    ...mapGetters('sprint', ['getProjectSprints']),
    ...mapGetters('userStory', ['getSprintUserStories']),
    ...mapGetters('userAcceptanceTest', ['getUserStoryUserAcceptanceTests']),
    ...mapGetters('uatValidation', ['getUserAcceptanceTestUatValidations']),
    ...mapGetters('user', ['getScopeUser']),
    ...mapGetters('app', ['getDashboardIds']),

    getConnectedUserId() {
      return this.getScopeUser().id;
    },

    defaultUserAcceptanceTestsId() {
      return this.getDashboardIds().userAcceptanceTest;
    },

    defaultUserStoriesId() {
      return this.getDashboardIds().userStory;
    },

    defaultSprintsId() {
      return this.getDashboardIds().sprint;
    },

    defaultProjectsId() {
      return this.getDashboardIds().project;
    },

    uatValidations() {
      return this.getUserAcceptanceTestUatValidations();
    },

    userAcceptanceTests() {
      return this.getUserStoryUserAcceptanceTests();
    },

    selectedUserAcceptanceTest() {
      if (
        this.userAcceptanceTests &&
        this.uatValidation.user_acceptance_tests_id
      ) {
        return this.userAcceptanceTests.filter((item) => {
          return (
            parseInt(item.id) ===
            parseInt(this.uatValidation.user_acceptance_tests_id)
          );
        })[0];
      } else if (
        this.userAcceptanceTests &&
        this.getDashboardIds().userAcceptanceTest
      ) {
        return this.userAcceptanceTests.filter((item) => {
          return (
            parseInt(item.id) ===
            parseInt(this.getDashboardIds().userAcceptanceTest)
          );
        })[0];
      } else {
        return null;
      }
    },

    userStories() {
      return this.getSprintUserStories();
    },

    selectedUserStories() {
      if (this.userStories && this.uatValidation.user_stories_id) {
        return this.userStories.filter((item) => {
          return (
            parseInt(item.id) === parseInt(this.uatValidation.user_stories_id)
          );
        })[0];
      } else if (this.userStories && this.getDashboardIds().userStory) {
        return this.userStories.filter((item) => {
          return (
            parseInt(item.id) === parseInt(this.getDashboardIds().userStory)
          );
        })[0];
      } else {
        return null;
      }
    },

    sprints() {
      return this.getProjectSprints();
    },

    projects() {
      return this.getProjects();
    },

    stateErrors() {
      const errors = [];
      if (!this.$v.uatValidation.state.$dirty) return errors;
      !this.$v.uatValidation.state.required &&
        errors.push("L'état doit être sélectionné");
      return errors;
    },

    userAcceptanceTestsErrors() {
      const errors = [];
      if (!this.$v.uatValidation.user_acceptance_tests_id.$dirty) return errors;
      !this.$v.uatValidation.user_acceptance_tests_id.required &&
        errors.push('Un UAT doit être sélectionné');
      !this.$v.uatValidation.user_acceptance_tests_id.number.numeric &&
        errors.push('UN UAT doit être sélectionné');
      return errors;
    },
  },

  mounted() {
    if (this.$route.params.id > 0) {
      this.formType = this.formTypes.edit;
      this.uatValidation.id = parseInt(
        this.uatValidation.id || this.$route.params.id
      );
    } else {
      this.formType = this.formTypes.create;
      this.uatValidation.state = 'FAILED';
      if (this.defaultUserAcceptanceTestsId > 0) {
        this.uatValidation.user_acceptance_tests_id = parseInt(
          this.defaultUserAcceptanceTestsId
        );
      }
      if (this.defaultProjectsId > 0) {
        this.uatValidation.projects_id = parseInt(this.defaultProjectsId);
      }
      if (this.defaultSprintsId > 0) {
        this.uatValidation.sprints_id = parseInt(this.defaultSprintsId);
      }
      if (this.defaultUserStoriesId > 0) {
        this.uatValidation.user_stories_id = parseInt(
          this.defaultUserStoriesId
        );
      }
    }
    this.showLoader();
    this.get_projects()
      .then((response) => {
        console.log('Projects OK', response);
        if (this.uatValidation.projects_id > 0) {
          this.get_project_sprints(this.uatValidation.projects_id)
            .then((response) => {
              console.log('Sprints OK', response);
              if (this.uatValidation.sprints_id > 0) {
                this.get_sprint_user_stories(this.uatValidation.sprints_id)
                  .then((response) => {
                    console.log('User Stories OK', response);
                    if (this.uatValidation.user_stories_id > 0) {
                      this.get_user_story_user_acceptance_tests(
                        this.uatValidation.user_stories_id
                      )
                        .then((response) => {
                          console.log('User Acceptance Tests OK', response);
                        })
                        .catch((error) => {
                          console.log('Erreur', error);
                          this.snack({
                            position: 'bottom',
                            align: 'center',
                            color: 'error',
                            text:
                              'Obtention des User Acceptance Tests impossible : ' +
                              error.message,
                          });
                        });
                    }
                  })
                  .catch((error) => {
                    console.log('Erreur', error);
                    this.snack({
                      position: 'bottom',
                      align: 'center',
                      color: 'error',
                      text:
                        'Obtention des User Stories impossible : ' +
                        error.message,
                    });
                  })
                  .finally(() => {
                    this.init = true;
                  });
              }
            })
            .catch((error) => {
              console.log('Erreur', error);
              this.snack({
                position: 'bottom',
                align: 'center',
                color: 'error',
                text: 'Obtention des Sprints impossible : ' + error.message,
              });
            });
        }
      })
      .catch((error) => {
        console.log('Erreur', error);
        this.snack({
          position: 'bottom',
          align: 'center',
          color: 'error',
          text: 'Obtention des Projets impossible : ' + error.message,
        });
      })
      .finally(() => {
        this.showLoader();
      });
  },

  methods: {
    ...mapActions('project', ['get_projects']),
    ...mapActions('sprint', ['get_project_sprints']),
    ...mapActions('userStory', ['get_sprint_user_stories']),
    ...mapActions('userAcceptanceTest', [
      'get_user_story_user_acceptance_tests',
    ]),
    ...mapActions('uatValidation', [
      'get_user_acceptance_test_uat_validations',
    ]),
    ...mapMutations('app', ['showLoader']),

    getDataProjectSprints(projectsId) {
      if (this.init) {
        this.showLoader();
        this.get_project_sprints(projectsId)
          .then((response) => {
            console.log('Sprints OK', response);
            if (this.init) {
              this.uatValidation.sprints_id = null;
            }
          })
          .catch((error) => {
            console.log('Erreur', error);
            this.snack({
              position: 'bottom',
              align: 'center',
              color: 'error',
              text: 'Obtention des Sprints impossible : ' + error.message,
            });
          })
          .finally(() => {
            this.showLoader();
          });
      }
    },

    getDataSprintUserStories(sprintsId) {
      if (this.init) {
        this.showLoader();
        this.get_sprint_user_stories(sprintsId)
          .then((response) => {
            console.log('User Stories OK', response);
            if (this.init) {
              this.uatValidation.user_stories_id = null;
            }
          })
          .catch((error) => {
            console.log('Erreur', error);
            this.snack({
              position: 'bottom',
              align: 'center',
              color: 'error',
              text: 'Obtention des User Stories impossible : ' + error.message,
            });
          })
          .finally(() => {
            this.showLoader();
          });
      }
    },

    getDataUserStoryUserAcceptanceTests(userStoriesId) {
      if (this.init) {
        this.showLoader();
        this.get_user_story_user_acceptance_tests(userStoriesId)
          .then((response) => {
            console.log('User Acceptance Test OK', response);
            if (this.init) {
              this.uatValidation.user_acceptance_tests_id = null;
            }
          })
          .catch((error) => {
            console.log('Erreur', error);
            this.snack({
              position: 'bottom',
              align: 'center',
              color: 'error',
              text:
                'Obtention des User Acceptance Tests impossible : ' +
                error.message,
            });
          })
          .finally(() => {
            this.showLoader();
          });
      }
    },

    getDataUserAcceptanceTestUATValidations(userAcceptanceTestId) {
      if (this.init) {
        this.showLoader();
        this.get_user_acceptance_test_uat_validations(userAcceptanceTestId)
          .then((response) => {
            console.log('UAT Validations OK', response);
          })
          .catch((error) => {
            console.log('Erreur', error);
            this.snack({
              position: 'bottom',
              align: 'center',
              color: 'error',
              text:
                'Obtention des UAT Validations  impossible : ' + error.message,
            });
          })
          .finally(() => {
            this.showLoader();
          });
      }
    },

    save() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.uatValidation.id == null || this.uatValidation.id <= 0) {
          delete this.uatValidation.id;
        }
        if (this.formType === this.formTypes.create) {
          this.uatValidation.users_id = this.getConnectedUserId;
        }
        console.log(this.uatValidation);
        this.$emit('save', this.uatValidation);
        if (this.formType === this.formTypes.create) {
          this.uatValidation = {};
        }
      }
    },
  },
};
</script>
