<template>
  <v-flex v-if="(done > 0 || total > 0) && question <= 0" wrap>
    <v-icon v-if="done >= total && total > 0" color="success">
      <!-- progress 2 -->
      mdi-check-circle
    </v-icon>
    <v-icon v-else-if="done <= total && done > 0" color="warning">
      <!-- progress 1 -->
      mdi-alert-circle
    </v-icon>
    <v-icon v-else color="error">
      <!-- progress 0 -->
      mdi-close-circle
    </v-icon>
  </v-flex>
  <v-flex v-else-if="question > 0" wrap>
    <v-icon color="info">
      mdi-alarm-light
    </v-icon>
    <!-- <v-icon color="info">
      mdi-help-circle-outline
    </v-icon> -->
  </v-flex>
  <v-flex v-else wrap>
    <v-icon v-if="isDone" color="success">
      <!-- progress 2 -->
      mdi-check-circle
    </v-icon>
    <v-icon v-else color="error">
      <!-- progress 0 -->
      mdi-close-circle
    </v-icon>
  </v-flex>
</template>
<script>
export default {
  name: 'ProgressCircle',

  props: {
    done: {
      type: Number,
      required: false,
      default: 0,
    },
    question: {
      type: Number,
      required: false,
      default: 0,
    },
    total: {
      type: Number,
      required: false,
      default: 0,
    },
    isDone: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
<style></style>
