/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
  // LOGIN / LOGOUT
  {
    path: '/',
    name: 'Connexion',
    view: 'Login',
  },
  {
    path: '/logout',
    name: 'Déconnexion',
    view: 'Logout',
  },
  // CONTACTS
  {
    path: '/contact',
    name: 'Nous Contacter',
    view: 'Contact',
  },
  // DASHBOARDS
  {
    path: '/dashboard',
    name: 'Tableau de bord',
    view: 'Dashboard',
  },
  {
    path: '/admin/dashboard',
    name: 'Tableau de bord Administrateur',
    view: 'Admin/AdminDashboard',
  },
  // PROJECT
  {
    path: '/project/create',
    name: "Création d'un Projet",
    view: 'Project/ProjectCreateForm',
  },
  {
    path: '/project/:id',
    name: "Détail d'un Projet",
    view: 'Project/ProjectDetails',
  },
  {
    path: '/project/:id/edit',
    name: "Édition d'un Projet",
    view: 'Project/ProjectEditForm',
  },
  // SPRINT
  {
    path: '/sprint/create',
    name: "Création d'un Sprint",
    view: 'Sprint/SprintCreateForm',
  },
  {
    path: '/sprint/:id',
    name: "Détail d'un Sprint",
    view: 'Sprint/SprintDetails',
  },
  {
    path: '/sprint/:id/edit',
    name: "Édition d'un Sprint",
    view: 'Sprint/SprintEditForm',
  },
  // USER STORY
  {
    path: '/userStory/create',
    name: "Création d'une US",
    view: 'UserStory/UserStoryCreateForm',
  },
  {
    path: '/userStory/:id',
    name: "Détail d'une US",
    view: 'UserStory/UserStoryDetails',
  },
  {
    path: '/userStory/:id/edit',
    name: "Édition d'une US",
    view: 'UserStory/UserStoryEditForm',
  },
  // USER ACCEPTANCE TEST
  {
    path: '/userAcceptanceTest/create',
    name: "Création d'une UAT",
    view: 'UserAcceptanceTest/UserAcceptanceTestCreateForm',
  },
  {
    path: '/userAcceptanceTest/:id',
    name: "Détail d'une UAT",
    view: 'UserAcceptanceTest/UserAcceptanceTestDetails',
  },
  {
    path: '/userAcceptanceTest/:id/edit',
    name: "Édition d'une UAT",
    view: 'UserAcceptanceTest/UserAcceptanceTestEditForm',
  },
  // UAT VALIDATION
  {
    path: '/uatValidation/create',
    name: 'Recetter une UAT',
    view: 'UatValidation/UatValidationCreateForm',
  },
  {
    path: '/uatValidation/:id/edit',
    name: 'Recetter une UAT',
    view: 'UatValidation/UatValidationEditForm',
  },
  // CUSTOMERS FORMS
  {
    path: '/customer',
    name: 'Liste des clients',
    view: 'Admin/Customer/CustomerList',
  },
  {
    path: '/customer/create',
    name: "Création d'un client",
    view: 'Admin/Customer/CustomerCreateForm',
  },
  {
    path: '/customer/:id/edit',
    name: "Édition d'un client",
    view: 'Admin/Customer/CustomerEditForm',
  },
  // USERS FORMS
  {
    path: '/user',
    name: 'Liste des utilisateurs',
    view: 'Admin/User/UserList',
  },
  {
    path: '/user/create',
    name: "Création d'un utilisateur",
    view: 'Admin/User/UserCreateForm',
  },
  {
    path: '/user/:id/edit',
    name: "Édition d'un utilisateur",
    view: 'Admin/User/UserEditForm',
  },
];
