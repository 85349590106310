export default {
  getProjectSprints: state => () => {
    return state.projectSprints
  },
  getSprints: state => () => {
    return state.sprints
  },
  getSprint: state => () => {
    return state.sprint
  }
}
