<template>
  <v-dialog v-model="show" max-width="500px">
    <v-card>
      <v-card-title :primary-title="true" class="pa-4">
        <h3 class="text-uppercase font-weight-light">
          <v-icon v-if="iconTitle" class="mr-1">
            {{ iconTitle }}
          </v-icon>
          <v-icon v-else color="warning" class="mr-1">
            {{ typeDialog }}
          </v-icon>
          {{ title }}
        </h3>
      </v-card-title>
      <v-card-text class="text-xs-left">
        {{ msg }}
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="typeDialog == 'warning'"
          color="warning"
          flat
          @click="cancelAction"
        >
          <v-icon v-if="cancelIcon" class="mr-1">
            {{ cancelIcon }}
          </v-icon>
          {{ cancelLabel }}
        </v-btn>
        <v-spacer />
        <v-btn color="warning" flat @click="okAction">
          <v-icon v-if="okIcon" class="mr-1">
            {{ okIcon }}
          </v-icon>
          {{ okLabel }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogBox',

  props: {
    typeDialog: {
      type: String,
      required: false,
      default: 'warning',
    },
    iconTitle: {
      type: String,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    msg: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    cancelLabel: {
      type: String,
      required: false,
      default: 'Annuler',
    },
    cancelIcon: {
      type: String,
      required: false,
      default: null,
    },
    okLabel: {
      type: String,
      required: false,
      default: 'Confirmer',
    },
    okIcon: {
      type: String,
      required: false,
      default: null,
    },
  },

  mounted() {
    console.log('show:', this.show);
  },

  methods: {
    cancelAction() {
      console.log('EMIT cancel');
      this.$emit('cancel');
    },

    okAction() {
      console.log('EMIT OK');
      this.$emit('ok');
    },
  },
};
</script>
