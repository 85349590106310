<template>
  <v-flex
    wrap
  >
    <v-icon
      v-if="isDone"
      color="success"
    >
      <!-- progress 2 -->
      mdi-circle
    </v-icon>
    <v-icon
      v-else
      color="error"
    >
      <!-- progress 0 -->
      mdi-circle
    </v-icon>
  </v-flex>
</template>
<script>
export default {
  name: 'IsActiveProgressCircle',

  props: {
    isDone: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
<style></style>
