<template>
  <v-card>
    <v-card-title class="text-uppercase headline font-weight-thin">
      Actions
    </v-card-title>
    <v-card-actions class="pb-4">
      <v-layout column>
        <slot />
      </v-layout>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: 'ActionsBox'
}
</script>
<style></style>
