<template>
  <v-data-table
    :headers="getHeaders"
    :items="datas"
    :pagination.sync="pagination"
  >
    <template slot="headerCell" slot-scope="{ header }">
      <span class="font-weight-light text--darken-3" v-html="header.text" />
    </template>
    <template slot="items" slot-scope="{ item }">
      <td
        :class="itemType == 'uatValidation' ? '' : 'pointer'"
        class="text-xs-center"
        @click="emitOnRowCLick(item.id)"
      >
        <p v-if="itemType == 'uatValidation'">
          <v-icon
            v-if="item.users && item.users.is_admin"
            color="primary"
            size="16"
          >
            mdi-cube
          </v-icon>
          <v-icon
            v-else
            :color="item.own_project ? 'success' : 'primary'"
            size="15"
          >
            mdi-face
          </v-icon>
          {{ item.users_name }}
        </p>
        <p v-else>
          {{ item.name }}
        </p>
        <!-- <small> #{{item.id }} </small> -->
      </td>
      <td
        :class="[
          itemType == 'userAcceptanceTest' || itemType == 'uatValidation'
            ? 'text-xs-center'
            : 'text-xs-left',
          itemType == 'uatValidation' ? '' : 'pointer',
        ]"
        @click="emitOnRowCLick(item.id)"
      >
        <p v-if="itemType == 'uatValidation'">
          {{ item.customers_name }}
        </p>
        <v-badge
          v-else-if="itemType == 'userAcceptanceTest'"
          :content="item[nbName]"
          :value="item[nbName]"
          overlap
        >
          <ui-ProgressCircle :is-done="item[nbName] > 0" />
        </v-badge>
        <v-flex v-else>
          {{
            item[nbName] > 0
              ? item[nbName + '_done'] + '/' + item[nbName]
              : 'Aucun'
          }}
        </v-flex>
      </td>
      <td
        v-if="itemType == 'uatValidation'"
        :class="itemType == 'uatValidation' ? '' : 'pointer'"
      >
        {{ item.comment }}
      </td>
      <td
        class="text-xs-center"
        :class="itemType == 'uatValidation' ? '' : 'pointer'"
        @click="emitOnRowCLick(item.id)"
      >
        <ui-UatValidationProgressCircle
          v-if="itemType == 'uatValidation'"
          :done="item.is_done"
        />
        <ui-ProgressCircle
          v-else-if="itemType == 'userAcceptanceTest'"
          :done="item.is_done"
          :total="2"
        />
        <ui-ProgressCircle
          v-else
          :done="item[nbName + '_done']"
          :total="item[nbName]"
        />
      </td>
      <td
        class="text-xs-left"
        :class="itemType == 'uatValidation' ? '' : 'pointer'"
        @click="emitOnRowCLick(item.id)"
      >
        {{ item.created_at }}
      </td>
      <td
        v-if="
          headers.map((item) => item.text).includes('Action') &&
            (isAdmin ||
              datas.map((item) => item.users_id).includes(getConnectedUsersId))
        "
        class="text-xs-center"
        :class="itemType == 'uatValidation' ? '' : 'pointer'"
      >
        <v-btn
          :disabled="
            !isAdmin &&
              !datas.map((item) => item.users_id).includes(getConnectedUsersId)
          "
          class="mr-1"
          text
          icon
          @click="emitEditOnRowCLick(item.id)"
        >
          <v-icon color="info">
            mdi-circle-edit-outline
          </v-icon>
        </v-btn>
        <v-btn
          :disabled="
            !isAdmin &&
              !datas.map((item) => item.users_id).includes(getConnectedUsersId)
          "
          class="ml-1"
          text
          icon
          @click="emitDeleteOnRowCLick(item.id)"
        >
          <v-icon color="error">
            mdi-delete-outline
          </v-icon>
        </v-btn>
      </td>
    </template>
  </v-data-table>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'DataTableDashboard',

  props: {
    datas: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    itemType: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    pagination: {
      sortBy: 'created_at',
      descending: false,
      rowsPerPage: 25, // -1 for All",
    },
  }),

  computed: {
    ...mapGetters('user', ['getScopeUser']),

    getHeaders() {
      let headers = this.headers;
      if (
        this.itemType === 'uatValidation' &&
        this.$route.path === '/dashboard' &&
        !this.headers.map((item) => item.text).includes('Action')
      ) {
        headers.push({
          sortable: false,
          text: 'Action',
          align: 'center',
        });
      }
      return headers;
    },

    getConnectedUsersId() {
      return this.getScopeUser().id || 0;
    },

    nbName() {
      return this.itemType
        ? {
            project: 'nb_sprints',
            sprint: 'nb_user_stories',
            userStory: 'nb_user_acceptance_tests',
            userAcceptanceTest: 'nb_uat_validations',
          }[this.itemType]
        : null;
    },
  },

  methods: {
    emitOnRowCLick(id) {
      this.$emit('onRowCLick', { id: id });
    },
    emitEditOnRowCLick(id) {
      this.$emit('editRow', { id: id });
    },
    emitDeleteOnRowCLick(id) {
      this.$emit('deleteRow', { id: id });
    },
  },
};
</script>
<style>
.pointer {
  cursor: pointer;
}
</style>
