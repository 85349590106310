
<template>
  <v-snackbar
    v-model="showme"
    :color="color"
    :bottom="bottom"
    :top="top"
    :left="left"
    :right="right"
    dark
  >
    <v-icon
      color="white"
      class="mr-3"
    >
      {{ getIcon }}
    </v-icon>
    <div v-html="text" />
  </v-snackbar>
</template>
<script>
export default {
  name: 'SnackbarV2',

  props: {
    color: {
      type: String,
      required: false,
      default: 'success'
    },

    bottom: {
      type: Boolean,
      required: false
    },

    top: {
      type: Boolean,
      required: false
    },

    left: {
      type: Boolean,
      required: false
    },

    right: {
      type: Boolean,
      required: false
    },

    text: {
      type: String,
      required: true
    },

    snackbar: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data: () => ({
    showme: false,
    icon: 'mdi-bell-plus'
  }),

  computed: {
    getIcon () {
      // change icon based on color asked
      switch (this.color) {
        case 'success':
          this.icon = 'mdi-bell-plus'
          break
        case 'warning':
          this.icon = 'mdi-alert-outline'
          break
        case 'error':
          this.icon = 'mdi-alert-circle'
          break
        case 'info':
          this.icon = 'mdi-information'
          break
      }
      return this.icon
    }
  },

  watch: {
    snackbar () {
      this.showme = this.snackbar
    }
  }

  // mounted(){
  //     this.showme = this.snackbar
  // }
}
</script>
<style></style>
