<template>
  <v-layout row>
    <v-flex
      xs12
      md4
      class="mr-2"
    >
      <v-text-field
        v-model="$v.localZipcode.$model"
        :disabled="disabled"
        mask="#####"
        label="Code postal"
        outline
        :error-messages="zipcodeErrors"
        @input="fetchCities"
      />
    </v-flex>
    <v-flex
      xs12
      md8
    >
      <!-- <v-autocomplete
        v-model="$v.localCity.$model"
        :items="cities"
        :loading="loading"
        item-text="description"
        dense
        browser-autocomplete="new-password"
        auto-select-first
        no-filter
        preprend-icon="my_location"
        no-data-text="Aucun résultat"
        hide-no-data
        label="Ville"
        return-object
        outline
        :error-messages="cityErrors"
        @change="setCity"
      /> -->
      <v-combobox
        v-model="$v.localCity.$model"
        :items="cities"
        :loading="loading"
        :disabled="disabled"
        item-text="description"
        dense
        browser-autocomplete="new-password"
        auto-select-first
        no-filter
        preprend-icon="my_location"
        no-data-text="Aucun résultat"
        hide-no-data
        label="Ville"
        return-object
        outline
        :error-messages="cityErrors"
        @change="setCity"
      />
    </v-flex>
  </v-layout>
</template>
<script>
import { HTTP_out } from '@/plugins/axios'
import { validationMixin } from 'vuelidate'
import {
  required,
  minLength,
  maxLength,
  numeric
} from 'vuelidate/lib/validators'
export default {
  name: 'ZipcodeAndCity',
  mixins: [validationMixin],

  props: {
    entity: {
      required: true,
      type: Object
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    features: [],
    loading: false,
    localZipcode: '',
    localCity: ''
  }),

  computed: {
    cities () {
      if (this.features.length) {
        return this.features.map(feat => {
          return Object.assign({}, feat, {
            description: this.getFeatureDescription(feat)
          })
        })
      } else {
        return new Array(this.localCity)
      }
    },

    cityErrors () {
      const errors = []
      if (!this.$v.localCity.$error) return errors
      !this.$v.localCity.required &&
        errors.push("La ville de l'établissement du registre est obligatoire")
      return errors
    },

    zipcodeErrors () {
      const errors = []
      if (!this.$v.localZipcode.$error) return errors
      !this.$v.localZipcode.required &&
        errors.push(
          "Le code postal de l'établissement du registre est obligatoire"
        )
      !this.$v.localZipcode.numeric &&
        !this.$v.localZipcode.minLength &&
        !this.$v.localZipcode.maxLength
      errors.push(
        "Le code postal de l'établissement du registre doit contenir 5 chiffres"
      )
      return errors
    }
  },

  validations: {
    localZipcode: {
      required,
      numeric,
      minLength: minLength(5),
      maxLength: maxLength(5)
    },
    localCity: {
      required
    }
  },

  // watch(){
  //   console.log(this.localZipcode,this.entity)
  //   if(this.localZipcode !== this.entity.zipcode){
  //     this.localZipcode = this.entity.zipcode
  //   }
  //   if(this.localCity !== this.entity.city){
  //     this.localCity = this.entity.city
  //   }
  // },

  mounted () {
    this.localZipcode = this.entity.zipcode || ''
    this.localCity = this.entity.city || ''
    // console.log(this.entity)
    // this.setCity({properties: {
    //   description: this.entity.city
    // }})
  },

  methods: {
    getFeatureDescription (city) {
      let description
      const properties = city.properties
      if (properties.type === 'locality') {
        description = properties.name + ' - ' + properties.city
      }
      if (properties.type === 'municipality') {
        description = properties.name.toUpperCase()
      }
      return description
    },

    fetchCities (search) {
      // console.log(this.localZipcode, this.entity)
      this.$emit(
        'update:entity',
        Object.assign(this.entity, { zipcode: this.localZipcode })
      )
      if (this.$v.localZipcode.$invalid) {
        return
      }
      this.loading = true
      HTTP_out.get(
        'https://api-adresse.data.gouv.fr/search/?q=' +
          search +
          '&type=municipality'
      )
        .then(response => {
          this.features = response.data.features
          this.features.unshift()
          if (this.features.length == 1) {
            this.setCity(this.features[0])
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    setCity (feature) {
      console.log('SET CITY', feature, typeof (feature))
      if (typeof (feature) === 'string') {
        this.$emit(
          'update:entity',
          Object.assign(this.entity, { city: feature.toUpperCase() })
        )
        console.log('emit')
      } else {
        if (feature !== null) {
          this.localCity = this.getFeatureDescription(feature)
          this.$emit(
            'update:entity',
            Object.assign(this.entity, { city: this.localCity })
          )
        }
      }
    }
  }
}
</script>
<style>
</style>
