<template>
  <v-btn
    color="black"
    @click="goBack"
  >
    <v-icon class="mr-3">
      mdi-format-list-bulleted
    </v-icon>
    Retour à la liste
  </v-btn>
</template>

<script>
export default {
  name: 'BackButton',

  methods: {
    // back to list
    goBack () {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    }
  }
}
</script>

<style>

</style>
