import { HTTP } from '@/plugins/axios'
import qs from 'qs'

const urlGroup = '/sprints'

export const SprintService = {
  get_sprints () {
    return HTTP.get(`${urlGroup}`)
  },
  get_project_sprints (projectsId) {
    return HTTP.get(`${urlGroup}/project/${projectsId}`)
  },
  get_sprint_by_id (sprintsId) {
    return HTTP.get(`${urlGroup}/${sprintsId}`)
  },
  delete_sprint_by_id (sprintsId) {
    return HTTP.delete(`${urlGroup}/${sprintsId}`)
  },
  update_sprint (sprints) {
    return HTTP.put(`${urlGroup}/${sprints.id}`, qs.stringify(sprints))
  },
  create_sprint (sprints) {
    return HTTP.post(`${urlGroup}`, qs.stringify(sprints))
  }
}
