import { HTTP } from '@/plugins/axios'
import qs from 'qs'

const urlGroup = '/userAcceptanceTests'

export const UserAcceptanceTestService = {
  get_user_story_user_acceptance_tests (userStoriesId) {
    return HTTP.get(`${urlGroup}/userStory/${userStoriesId}`)
  },
  get_user_acceptance_tests_by_id (userAcceptanceTestsId) {
    return HTTP.get(`${urlGroup}/${userAcceptanceTestsId}`)
  },
  delete_user_acceptance_tests_by_id (userAcceptanceTestsId) {
    return HTTP.delete(`${urlGroup}/${userAcceptanceTestsId}`)
  },
  update_user_acceptance_tests (userAcceptanceTests) {
    return HTTP.put(`${urlGroup}/${userAcceptanceTests.id}`, qs.stringify(userAcceptanceTests))
  },
  create_user_acceptance_tests (userAcceptanceTests) {
    return HTTP.post(`${urlGroup}`, qs.stringify(userAcceptanceTests))
  }
}
