<template>
  <div>
    <v-badge
      v-if="counter < 100"
      color="black"
      overlap
      right
    >
      <span slot="badge">{{ counter }}</span>
    </v-badge>
    <v-chip
      v-else
      color="black"
      text-color="white"
    >
      {{ counter }}
    </v-chip>
  </div>
</template>

<script>
export default {
  name: 'BadgeDisplayNumber',

  props: {
    counter: {
      type: Number,
      required: true,
      default: 0
    }
  }

}
</script>

<style>

</style>
