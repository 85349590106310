/**
 * Vuex
 *
 * @library
 *
 * https://vuex.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import Vuex from 'vuex'
// import sharedMutations from 'vuex-shared-mutations'

// Store functionality
import modules from './modules'

Vue.use(Vuex)

// Create a new store
const store = new Vuex.Store({
  // plugins: [sharedMutations({ predicate: ['SET_PROVIDER_MEMBER'] })],

  mutations: {
    GET_EVENTS: (state, { data }) => {
      state.events = data
    }
  },

  modules
})

export default store
