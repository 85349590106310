<template>
  <v-snackbar
    v-model="show"
    :timeout="6000"
    right
    top
    :color="color"
    class="text-xs-left"
  >
    <v-icon
      v-if="color == 'error'"
      color="white"
      class="mr-2"
    >
      error
    </v-icon>
    <v-icon
      v-if="color == 'success'"
      color="white"
      class="mr-2"
    >
      check_circle
    </v-icon>
    <div v-html="text" />
  </v-snackbar>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'Snackbar',

  props: {
    color: {
      type: String,
      required: false,
      default: 'success'
    },
    text: {
      type: String,
      required: true
    }
  },

  methods: {
    ...mapMutations('app', ['hideSnack'])
  },

  computed: {
    ...mapState('app', ['snackVisible']),
    show: {
      get () {
        // console.log('state snack',this.snackVisible)
        return this.snackVisible
        // console.log('state snack',this.snackVisible)
      },
      set (val) {
        this.hideSnack
      }
    }
  }
}
</script>
<style></style>
