<template>
  <v-flex>
    <v-card>
      <v-card-title>
        Liste des utilisateurs
        <v-spacer></v-spacer>
        <v-text-field
          v-if="canSearch"
          v-model="search"
          append-icon="mdi-magnify"
          label="Recherche rapide"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="computedUsers"
        :loading="loading"
        :pagination.sync="pagination"
        :search="search"
      >
        <template slot="headerCell" slot-scope="{ header }">
          <span class="font-weight-light text--darken-3">
            {{ header.text }}
          </span>
        </template>
        <template slot="items" slot-scope="{ item }">
          <td class="text-xs-center">
            <v-icon
              v-if="getConnectedUsersId == item.id"
              size="17"
              color="quaternary"
            >
              mdi-map-marker
            </v-icon>
            {{ item.name }}
          </td>
          <td class="text-xs-center">
            {{ item.customers_name }}
          </td>
          <td class="text-xs-center">
            <ui-IsActiveProgressCircle :is-done="item.is_active > 0" />
          </td>
          <td class="text-xs-left">
            <v-icon v-if="item.is_admin" color="primary" size="16">
              mdi-cube
            </v-icon>
            <v-icon v-else color="primary" size="15">
              mdi-face
            </v-icon>
            {{ item.roles_label || '' }}
          </td>
          <td class="text-xs-left">
            {{ item.email }}
          </td>
          <td class="text-xs-left">
            {{ item.created_at }}
          </td>
          <td
            v-if="
              headers.map((item) => item.text).includes('Action') && isAdmin
            "
            class="text-xs-center"
          >
            <v-btn
              v-if="isAdmin"
              :disabled="!isAdmin"
              class="mr-1"
              text
              icon
              @click="emitEditOnRowCLick(item.id)"
            >
              <v-icon color="info">
                mdi-circle-edit-outline
              </v-icon>
            </v-btn>
            <v-btn
              v-if="isAdmin"
              :disabled="!isAdmin"
              class="ml-1"
              text
              icon
              @click="emitDeleteOnRowCLick(item.id, item.is_active)"
            >
              <v-icon v-if="item.is_active >= 1" color="error">
                mdi-close-circle-outline
              </v-icon>
              <v-icon v-else color="success">
                mdi-check-circle-outline
              </v-icon>
            </v-btn>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-flex>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DataTableUser',

  props: {
    users: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    canSearch: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    pagination: {
      sortBy: 'is_active',
      descending: true,
      rowsPerPage: 25, // -1 for All",
    },
    search: '',
  }),

  computed: {
    ...mapGetters('user', ['getScopeUser']),
    ...mapGetters('app', ['getUserHeaders']),

    headers() {
      return this.getUserHeaders();
    },

    computedUsers() {
      return this.users.map((item) => {
        return {
          ...item,
          roles_label: item.roles.label,
        };
      });
    },

    getConnectedUsersId() {
      return this.getScopeUser().id || 0;
    },
  },

  methods: {
    emitOnRowCLick(id) {
      this.$emit('onRowCLick', { id: id });
    },
    emitEditOnRowCLick(id) {
      this.$emit('editRow', { id: id });
    },
    emitDeleteOnRowCLick(id, isActive) {
      this.$emit('deleteRow', { id: id, is_active: isActive });
    },
  },
};
</script>
<style></style>
