import { set } from '@/utils/vuex'

export default {
  SET_CUSTOMER_PROJECTS: set('customerProjects'),
  SET_PROJECTS: set('projects'),
  SET_PROJECT: set('project'),
  DELETE_PROJECT: (state, projectsId) => {
    state.customerProjects = state.customerProjects.filter(item => { return item.id !== projectsId })
    state.projects = state.projects.filter(item => { return item.id !== projectsId })
    state.project = state.project.id !== projectsId ? state.project : {}
  },
  ADD_PROJECT: (state, projects) => {
    let index = state.customerProjects.map(item => item.id).indexOf(projects.id)
    index >= 0 ? state.customerProjects[index] = projects : state.customerProjects.push(projects)
    index = state.projects.map(item => item.id).indexOf(projects.id)
    index >= 0 ? state.projects[index] = projects : state.projects.push(projects)
    state.project = projects
  }
}
