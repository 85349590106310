<template>
  <v-dialog
    v-model="show"
    class="text-xs-center"
    width="400"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        :small="small"
        color="error"
        :flat="flat"
        :icon="icon"
        class="ma-0 pa-0"
        v-on="on"
      >
        <v-icon
          v-if="buttonIcon"
          :small="small"
          class="mr-1"
        >
          {{ buttonIcon }}
        </v-icon>
        {{ buttonLabel }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title
        v-if="title"
        primary-title
        class="px-4 py-2"
      >
        <h3 class="title text-uppercase font-weight-light">
          {{ title }}
        </h3>
      </v-card-title>
      <v-card-text
        class="text-xs-left"
        v-html="content"
      />
      <v-card-actions>
        <v-btn
          flat
          color="warning"
          @click="close"
        >
          <v-icon
            v-if="cancelButtonIcon"
            class="mr-1"
          >
            {{ cancelButtonIcon }}
          </v-icon>
          {{ cancelButtonLabel }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="error"
          flat
          @click="closeWithCallback"
        >
          <v-icon
            v-if="deleteButtonIcon"
            class="mr-1"
          >
            {{ deleteButtonIcon }}
          </v-icon>
          {{ deleteButtonLabel }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeleteDialogBox',

  props: {
    flat: {
      type: Boolean,
      required: false,
      default: false
    },
    small: {
      type: Boolean,
      required: false,
      default: false
    },
    icon: {
      type: Boolean,
      required: false,
      default: false
    },
    content: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    buttonLabel: {
      type: String,
      required: false
    },
    buttonIcon: {
      type: String,
      required: true,
      default: 'delete'
    },
    cancelButtonLabel: {
      type: String,
      required: true
    },
    cancelButtonIcon: {
      type: String,
      required: false
    },
    deleteButtonLabel: {
      type: String,
      required: true
    },
    deleteButtonIcon: {
      type: String,
      required: false
    },
    callback: {
      type: Function,
      required: true
    },
    callbackArgs: {
      type: Array,
      required: false,
      default: []
    }
  },

  data: () => ({
    show: false
  }),

  methods: {
    close () {
      this.show = false
    },

    closeWithCallback () {
      this.show = false
      if (this.callback) this.callback.apply(this, this.callbackArgs)
    }
  }
}
</script>

<style></style>
