import { UatValidationService } from '@/store/services/UatValidationService'
import handleApiError from '@/helpers/handleApiError'

export default {
  get_user_acceptance_test_uat_validations (context, userAcceptanceTestsId) {
    return new Promise((resolve, reject) => {
      UatValidationService.get_user_acceptance_test_uat_validations(userAcceptanceTestsId)
        .then(response => {
          var data = response.data
          context.commit('SET_USER_ACCEPTANCE_TEST_UAT_VALIDATIONS', data)
          resolve(data)
        })
        .catch(error => {
          handleApiError(error, reject)
        })
    })
  },
  get_uat_validations_by_id (context, uatValidationsId) {
    return new Promise((resolve, reject) => {
      UatValidationService.get_uat_validations_by_id(uatValidationsId)
        .then(response => {
          var data = response.data
          context.commit('SET_UAT_VALIDATION', data)
          resolve(data)
        })
        .catch(error => {
          handleApiError(error, reject)
        })
    })
  },
  delete_uat_validations_by_id (context, uatValidationsId) {
    return new Promise((resolve, reject) => {
      UatValidationService.delete_uat_validations_by_id(uatValidationsId)
        .then(response => {
          var data = response.data
          context.commit('DELETE_UAT_VALIDATION', uatValidationsId)
          resolve(data)
        })
        .catch(error => {
          handleApiError(error, reject)
        })
    })
  },
  update_uat_validations (context, uatValidations) {
    return new Promise((resolve, reject) => {
      UatValidationService.update_uat_validations(uatValidations)
        .then(response => {
          var data = response.data
          context.commit('ADD_UAT_VALIDATION', uatValidations)
          resolve(data)
        })
        .catch(error => {
          handleApiError(error, reject)
        })
    })
  },
  create_uat_validations (context, uatValidations) {
    return new Promise((resolve, reject) => {
      UatValidationService.create_uat_validations(uatValidations)
        .then(response => {
          var data = response.data
          context.commit('ADD_UAT_VALIDATION', uatValidations)
          resolve(data)
        })
        .catch(error => {
          handleApiError(error, reject)
        })
    })
  }
}
