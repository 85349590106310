import { HTTP } from '@/plugins/axios'
import qs from 'qs'

const urlGroup = '/contacts'

export const ContactService = {
  get_contacts () {
    return HTTP.get(`${urlGroup}`)
  },
  get_contact (id) {
    return HTTP.get(`${urlGroup}/${id}`)
  },
  create_contact (contacts) {
    return HTTP.post(`${urlGroup}`, qs.stringify(contacts))
  }
}
