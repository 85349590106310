export default {
  getUsers: (state) => () => {
    return state.users;
  },

  getUser: (state) => () => {
    if (state.user && Object.keys(state.user).length) {
      return state.user;
    } else {
      let user = JSON.parse(localStorage.getItem('user'));
      return user;
    }
  },

  getCurrentUser: (state) => () => {
    return state.user;
  },

  getScopeUser: (state) => () => {
    return JSON.parse(localStorage.getItem('user'));
  },

  getCustomer: (state) => () => {
    return JSON.parse(localStorage.getItem('user')).customers;
  },
};
