<template>
  <div class="text-xs-left ma-3">
    <div>
      <h4 class="text--grey text--lighten-3 subheading font-weight-bold">
        <v-icon v-if="icon">
          {{ icon }}
        </v-icon>
        {{ label }}
      </h4>
      <div class="text--grey py-2 subheading" style="min-height: 40px">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EntityMetadata',
  props: {
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: null,
    },
  },
};
</script>

<style></style>
