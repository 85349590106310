<template>
  <v-flex v-if="isAdmin && formType != ''" wrap ma-5>
    <v-text-field
      v-model="customer.name"
      label="Nom du client"
      :error-messages="nameErrors"
      :disabled="loading"
    />
    <v-flex>
      <v-flex left>
        <v-btn center class="quaternary" @click="$router.go(-1)">
          <v-icon>mdi-arrow-left</v-icon>
          <v-flex ml-2 pa-0>
            Retour
          </v-flex>
        </v-btn>
      </v-flex>
      <v-flex right>
        <v-btn
          :disabled="!isAdmin || $v.$invalid || loading"
          center
          class="success"
          @click="save()"
        >
          <v-icon>mdi-content-save-outline</v-icon>
          <v-flex ml-2 pa-0>
            Sauvegarder
          </v-flex>
        </v-btn>
      </v-flex>
    </v-flex>
  </v-flex>
  <v-flex v-else wrap ma-5>
    Aucun formulaire
  </v-flex>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
// import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'CustomerForm',

  props: {
    customer: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    formType: '',
    formTypes: {
      edit: 'EDIT',
      create: 'CREATE',
    },
    init: false,
  }),

  validations: {
    customer: {
      name: {
        required,
      },
    },
  },

  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.customer.name.$dirty) return errors;
      !this.$v.customer.name.required && errors.push('Le nom est obligatoire');
      return errors;
    },
  },

  mounted() {
    if (this.$route.params.id > 0) {
      this.formType = this.formTypes.edit;
      this.customer.id = parseInt(this.customer.id || this.$route.params.id);
    } else {
      this.formType = this.formTypes.create;
    }
  },

  methods: {
    save() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.customer.id == null || this.customer.id <= 0) {
          delete this.customer.id;
        }
        this.$emit('save', this.customer);
        if (this.formType === this.formTypes.create) {
          this.customer = {};
        }
      }
    },
  },
};
</script>
