import { set, add } from '@/utils/vuex'

export default {

  SET_USER: set('user'),
  SET_USERS: set('users'),
  /* TODO change this old mutation to new one type */
  EDIT_USER (state, user) {
    if (state.users.length > 0) {
      let index = state.users.findIndex(function (element) {
        if (element.id === parseInt(user.id)) {
          return element
        }
      })
      if (index) {
        state.users[index] = user
      }
    }
    if (state.user.id === user.id) {
      state.user = user
    }
  },
  ACTIVATE_USER (state, id) {
    let index = state.users.map(item => item.id).indexOf(id)
    if (index >= 0) state.users[index].is_active = !state.users[index].is_active
    if (state.user.id === id) state.user.is_active = !state.user.is_active
  },
  REMOVE_USER (state, id) {
    state.users = state.users.filter(item => item.id !== id)
  },
  DELETE_USER: (state, usersId) => {
    state.users = state.users.filter(item => { return item.id !== usersId })
    state.user = state.user.id !== usersId ? state.user : {}
  },
  ADD_USER: (state, users) => {
    let index = state.users.map(item => item.id).indexOf(users.id)
    index >= 0 ? state.users[index] = users : state.users.push(users)
    state.user = users
  }
}
