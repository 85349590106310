<template>
  <v-tooltip top>
    <v-chip
      slot="activator"
      :color="getColorDateBg()"
      class="white--text font-weight-medium"
    >
      <v-icon color="white" class="mr-1">
        mdi-calendar-range
      </v-icon>
      {{ dateToDisplay }}
    </v-chip>
    <span>{{ tooltipText }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'ColoredBadgeDate',
  props: {
    colorOK: {
      type: String,
      required: false,
      default: 'success',
    },
    tooltipText: {
      type: String,
      required: true,
    },
    dateInput: {
      type: String,
      required: true,
    },
    dateToDisplay: {
      type: String,
      required: true,
    },
  },
  methods: {
    getColorDateBg() {
      // var today = this.$moment()
      // var isoDate = this.$moment(this.dateInput)
      // var duration = this.$moment.duration(today.diff(isoDate))
      // var nbDays = duration.asDays()
      // console.log(parseInt(nbDays))
      // if (parseInt(nbDays) < 1) {
      //   return this.colorOK
      // } else if (parseInt(nbDays) <= 5 && parseInt(nbDays) >= 1) {
      //   return "warning"
      // } else if (parseInt(nbDays) > 5) {
      //   return 'error'
      // } else {
      return 'info';
      // }
    },
  },
};
</script>

<style></style>
