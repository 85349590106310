<template>
  <v-btn flat large :to="href" color="primary" :disabled="disabled">
    <v-icon v-if="icon" class="mr-1" large>
      {{ icon }}
    </v-icon>
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: 'EntityLink',
  props: {
    href: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
