<template>
  <EntityMetadataForm
    :help="showHelp"
    :help-content="helpContent"
    :help-title="helpTitle"
  >
    <v-flex slot="first">
      <v-text-field
        v-model.trim="tmp_var"
        :label="label"
        :error-messages="errorMessage"
        outline
        @keyup.enter="addItem"
        @change="addItem"
      />
      <v-flex v-if="dataArray.length > 0" class="mb-4">
        <!-- display -->
        <v-chip
          v-for="tmp in dataArray"
          :key="tmp"
          close
          :color="chipColor"
          @input="removeItem(tmp)"
        >
          {{ tmp }}
        </v-chip>
      </v-flex>
    </v-flex>

    <!-- <ActionButton
      slot="second"
      icon="add"
      iconcolor="white"
      :disabled="isEmpty"
      :label="addLabel"
      :callback="addItem"
      color="primary"
    /> -->
  </EntityMetadataForm>
</template>

<script>
// import { EntityMetadataForm, ActionButton, SnackBar } from '@/components'
import SnackBarMixin from '@/mixins/SnackBarMixin';

export default {
  name: 'AddMultipleField',

  // components: { EntityMetadataForm, ActionButton, SnackBar },

  mixins: [SnackBarMixin],

  props: {
    showHelp: {
      type: Boolean,
      required: false,
      default: false,
    },
    helpTitle: {
      type: String,
      required: false,
      default: '',
    },
    helpContent: {
      type: String,
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: true,
      default: '',
    },
    addLabel: {
      type: String,
      required: true,
      default: '',
    },
    dataArray: {
      type: Array,
      required: true,
      default: () => [],
    },
    chipColor: {
      type: String,
      required: false,
      default: 'grey',
    },
    errorMessage: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data: () => ({
    tmp_var: '',
  }),

  computed: {
    isEmpty() {
      return this.tmp_var.length === 0;
    },
  },

  watch: {
    dataArray(val) {
      // emit to the parent
      this.$emit('change', val);
    },
  },

  methods: {
    addItem() {
      if (this.tmp_var.length > 0) {
        // check if duplicate (/!\ important because the key is based on this)
        const index = this.dataArray.indexOf(this.tmp_var);
        if (index === -1) {
          this.dataArray.push(this.tmp_var);
          this.tmp_var = '';
        } else {
          this.showSnackBar('error', 'Valeur déjà existante');
        }
      }
    },

    removeItem(item) {
      const index = this.dataArray.indexOf(item);
      // console.log('item to remove find:',index)
      if (index >= 0) this.dataArray.splice(index, 1);
    },
  },
};
</script>

<style></style>
