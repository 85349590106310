export default {
  getUserStoryUserAcceptanceTests: state => () => {
    return state.userStoryUserAcceptanceTests
  },
  getUserAcceptanceTests: state => () => {
    return state.userAcceptanceTests
  },
  getUserAcceptanceTest: state => () => {
    return state.userAcceptanceTest
  }
}
