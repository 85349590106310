<template>
  <v-dialog
    v-model="showModal"
    width="290"
  >
    <v-card
      xs-12
      class="text-xs-center"
    >
      <v-card-title
        class="title grey lighten-3"
        primary-title
      >
        Traitement du fichier en cours...
      </v-card-title>
      <v-card-text>
        Veuillez patienter<br>
        <v-progress-circular
          :rotate="270"
          :size="100"
          :width="10"
          :value="percentage"
          :color="getColor"
        >
          {{ percentage | two_digits }}%
        </v-progress-circular>
        <p class="caption pt-3">
          <strong>{{ displayCountdown }}</strong> secondes estimées
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CounDown',

  props: {
    timeEstimated: {
      type: Number,
      required: false,
      default: 60
    },
    close: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    showModal: false,
    timeLeft: 0,
    timer: null
  }),

  computed: {
    /* return seconds for timer */
    displayCountdown () {
      return this.timeLeft
    },

    /* return % for timer */
    percentage () {
      let percent = 100 - (this.timeLeft / this.timeEstimated) * 100
      // console.log('%', percent)
      return percent
    },

    /* set the color for timer, depending of estimated % left */
    getColor () {
      if (this.percentage > 75) {
        return 'success'
      } else if (this.percentage > 50) {
        return 'info'
      } else if (this.percentage > 25) {
        return 'warning'
      } else if (this.percentage > 0) {
        return 'error'
      } else {
        return 'error'
      }
    }
  },

  watch: {
    timeEstimated (val) {
      this.timeLeft = val
      this.waitForTreatment(val)
    },
    close (val) {
      if (val === true) {
        this.showModal = false
      }
    }
  },

  methods: {
    /* Show or hide modal while waiting process */
    waitForTreatment (secondsToWait) {
      this.timeEstimated = secondsToWait
      if (secondsToWait > 0) {
        this.timeLeft = secondsToWait
      }
      this.timer = setInterval(() => {
        this.countDown()
      }, 1000)
      // console.info('time', this.timeLeft)
      this.showModal = true
    },

    countDown () {
      if (this.timeLeft >= 1) {
        this.timeLeft = this.timeLeft - 1
      } else if (this.timeLeft === 0) {
        this.showModal = false
      } else {
        clearInterval(this.timer)
      }
    }
  }
}
</script>

<style>

</style>
