<template>
  <v-flex v-if="isAdmin && formType != ''" wrap ma-5>
    <v-autocomplete
      v-model="userStory.projects_id"
      :items="projects"
      item-text="name"
      item-value="id"
      label="Projet"
      :disabled="loading"
      @change="getDataProjectSprints($event)"
    />
    <v-autocomplete
      v-model="userStory.sprints_id"
      :items="sprints"
      item-text="name"
      item-value="id"
      label="Sprint"
      :error-messages="sprintsErrors"
      :disabled="loading || !sprints.length"
    />
    <v-text-field
      v-model="userStory.name"
      label="Titre de la User Story"
      :error-messages="nameErrors"
      :disabled="loading"
    />
    <v-textarea
      v-model="userStory.comment"
      label="Commentaire"
      clear-icon="mdi-delete-outline"
      :disabled="loading"
      auto-grow
      clearable
      counter
    />
    <v-flex>
      <v-flex left>
        <v-btn center class="quaternary" @click="$router.go(-1)">
          <v-icon>mdi-arrow-left</v-icon>
          <v-flex ml-2 pa-0>
            Retour
          </v-flex>
        </v-btn>
      </v-flex>
      <v-flex right>
        <v-btn
          :disabled="!isAdmin || $v.$invalid || loading"
          center
          class="success"
          @click="save()"
        >
          <v-icon>mdi-content-save-outline</v-icon>
          <v-flex ml-2 pa-0>
            Sauvegarder
          </v-flex>
        </v-btn>
      </v-flex>
    </v-flex>
  </v-flex>
  <v-flex v-else wrap ma-5>
    Aucun formulaire
  </v-flex>
</template>

<script>
import { required, numeric } from 'vuelidate/lib/validators';
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: 'UserStoryForm',

  props: {
    userStory: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    formType: '',
    formTypes: {
      edit: 'EDIT',
      create: 'CREATE',
    },
    init: false,
  }),

  validations: {
    userStory: {
      name: {
        required,
      },
      sprints_id: {
        required,
        number: {
          numeric,
        },
      },
    },
  },

  computed: {
    ...mapGetters('project', ['getProjects']),
    ...mapGetters('sprint', ['getProjectSprints']),
    ...mapGetters('app', ['getDashboardIds']),

    defaultSprintsId() {
      return this.getDashboardIds().sprint;
    },

    defaultProjectsId() {
      return this.getDashboardIds().project;
    },

    sprints() {
      return this.getProjectSprints();
    },

    projects() {
      return this.getProjects();
    },

    nameErrors() {
      const errors = [];
      if (!this.$v.userStory.name.$dirty) return errors;
      !this.$v.userStory.name.required && errors.push('Le nom est obligatoire');
      return errors;
    },

    sprintsErrors() {
      const errors = [];
      if (!this.$v.userStory.sprints_id.$dirty) return errors;
      !this.$v.userStory.sprints_id.required &&
        errors.push('Le sprint est obligatoire');
      !this.$v.userStory.sprints_id.number.numeric &&
        errors.push('Le sprint est obligatoire');
      return errors;
    },
  },

  mounted() {
    if (this.$route.params.id > 0) {
      this.formType = this.formTypes.edit;
      this.userStory.id = parseInt(this.userStory.id || this.$route.params.id);
    } else {
      this.formType = this.formTypes.create;
      if (this.defaultSprintsId > 0) {
        this.userStory.sprints_id = parseInt(this.defaultSprintsId);
      }
      if (this.defaultProjectsId > 0) {
        this.userStory.projects_id = parseInt(this.defaultProjectsId);
      }
    }
    this.showLoader();
    this.get_projects()
      .then((response) => {
        console.log('Projects OK', response);
        if (this.userStory.projects_id > 0) {
          this.get_project_sprints(this.userStory.projects_id)
            .then((response) => {
              console.log('Sprints OK', response);
            })
            .catch((error) => {
              console.log('Erreur', error);
              this.snack({
                position: 'bottom',
                align: 'center',
                color: 'error',
                text: 'Obtention des Sprints impossible : ' + error.message,
              });
            })
            .finally(() => {
              this.init = true;
            });
        }
      })
      .catch((error) => {
        console.log('Erreur', error);
        this.snack({
          position: 'bottom',
          align: 'center',
          color: 'error',
          text: 'Obtention des Projets impossible : ' + error.message,
        });
      })
      .finally(() => {
        this.showLoader();
      });
  },

  methods: {
    ...mapActions('project', ['get_projects']),
    ...mapActions('sprint', ['get_project_sprints']),
    ...mapMutations('app', ['showLoader']),

    getDataProjectSprints(projectsId) {
      if (this.init) {
        this.showLoader();
        this.get_project_sprints(projectsId)
          .then((response) => {
            console.log('Sprints OK', response);
            if (this.init) {
              this.userStory.sprints_id = null;
            }
          })
          .catch((error) => {
            console.log('Erreur', error);
            this.snack({
              position: 'bottom',
              align: 'center',
              color: 'error',
              text: 'Obtention des Sprints impossible : ' + error.message,
            });
          })
          .finally(() => {
            this.showLoader();
          });
      }
    },

    save() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.userStory.id == null || this.userStory.id <= 0) {
          delete this.userStory.id;
        }
        this.$emit('save', this.userStory);
        if (this.formType === this.formTypes.create) {
          this.userStory = {};
        }
      }
    },
  },
};
</script>
