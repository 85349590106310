import { UserStoryService } from '@/store/services/UserStoryService'
import handleApiError from '@/helpers/handleApiError'

export default {
  get_sprint_user_stories (context, sprintsId) {
    return new Promise((resolve, reject) => {
      UserStoryService.get_sprint_user_stories(sprintsId)
        .then(response => {
          var data = response.data
          context.commit('SET_SPRINT_USER_STORIES', data)
          resolve(data)
        })
        .catch(error => {
          handleApiError(error, reject)
        })
    })
  },
  get_user_stories_by_id (context, userStoriesId) {
    return new Promise((resolve, reject) => {
      UserStoryService.get_user_stories_by_id(userStoriesId)
        .then(response => {
          var data = response.data
          context.commit('SET_USER_STORY', data)
          resolve(data)
        })
        .catch(error => {
          handleApiError(error, reject)
        })
    })
  },
  delete_user_stories_by_id (context, userStoriesId) {
    return new Promise((resolve, reject) => {
      UserStoryService.delete_user_stories_by_id(userStoriesId)
        .then(response => {
          var data = response.data
          context.commit('DELETE_USER_STORY', userStoriesId)
          resolve(data)
        })
        .catch(error => {
          handleApiError(error, reject)
        })
    })
  },
  update_user_stories (context, userStories) {
    return new Promise((resolve, reject) => {
      UserStoryService.update_user_stories(userStories)
        .then(response => {
          var data = response.data
          context.commit('ADD_USER_STORIES', userStories)
          resolve(data)
        })
        .catch(error => {
          handleApiError(error, reject)
        })
    })
  },
  create_user_stories (context, userStories) {
    return new Promise((resolve, reject) => {
      UserStoryService.create_user_stories(userStories)
        .then(response => {
          var data = response.data
          context.commit('ADD_USER_STORIES', userStories)
          resolve(data)
        })
        .catch(error => {
          handleApiError(error, reject)
        })
    })
  }
}
