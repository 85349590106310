<template>
  <v-flex v-if="isAdmin && formType != ''" wrap ma-5>
    <v-autocomplete
      v-model="sprint.projects_id"
      :items="projects"
      item-text="name"
      item-value="id"
      label="Projet"
      :error-messages="projectsErrors"
      :disabled="loading"
    />
    <v-text-field
      v-model="sprint.name"
      label="Nom du sprint"
      :error-messages="nameErrors"
      :disabled="loading"
    />
    <v-flex>
      <v-flex left>
        <v-btn center class="quaternary" @click="$router.go(-1)">
          <v-icon>mdi-arrow-left</v-icon>
          <v-flex ml-2 pa-0>
            Retour
          </v-flex>
        </v-btn>
      </v-flex>
      <v-flex right>
        <v-btn
          :disabled="!isAdmin || $v.$invalid || loading"
          center
          class="success"
          @click="save()"
        >
          <v-icon>mdi-content-save-outline</v-icon>
          <v-flex ml-2 pa-0>
            Sauvegarder
          </v-flex>
        </v-btn>
      </v-flex>
    </v-flex>
  </v-flex>
  <v-flex v-else wrap ma-5>
    Aucun formulaire
  </v-flex>
</template>

<script>
import { required, numeric } from 'vuelidate/lib/validators';
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: 'SprintForm',

  props: {
    sprint: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    formType: '',
    formTypes: {
      edit: 'EDIT',
      create: 'CREATE',
    },
  }),

  validations: {
    sprint: {
      name: {
        required,
      },
      projects_id: {
        required,
        number: {
          numeric,
        },
      },
    },
  },

  computed: {
    ...mapGetters('project', ['getProjects']),
    ...mapGetters('app', ['getDashboardIds']),

    defaultProjectsId() {
      return this.getDashboardIds().project;
    },

    projects() {
      return this.getProjects();
    },

    nameErrors() {
      const errors = [];
      if (!this.$v.sprint.name.$dirty) return errors;
      !this.$v.sprint.name.required && errors.push('Le nom est obligatoire');
      return errors;
    },

    projectsErrors() {
      const errors = [];
      if (!this.$v.sprint.projects_id.$dirty) return errors;
      !this.$v.sprint.projects_id.required &&
        errors.push('Le projet est obligatoire');
      !this.$v.sprint.projects_id.number.numeric &&
        errors.push('Le projet est obligatoire');
      return errors;
    },
  },

  mounted() {
    if (this.$route.params.id > 0) {
      this.formType = this.formTypes.edit;
      this.sprint.id = parseInt(this.sprint.id || this.$route.params.id);
    } else {
      this.formType = this.formTypes.create;
      if (this.defaultProjectsId > 0) {
        this.sprint.projects_id = parseInt(this.defaultProjectsId);
      }
    }
    this.showLoader();
    this.get_projects()
      .then((response) => {
        console.log('Projects OK', response);
      })
      .catch((error) => {
        console.log('Erreur', error);
        this.snack({
          position: 'bottom',
          align: 'center',
          color: 'error',
          text: 'Obtention des Projets impossible : ' + error.message,
        });
      })
      .finally(() => {
        this.showLoader();
      });
  },

  methods: {
    ...mapActions('project', ['get_projects']),
    ...mapMutations('app', ['showLoader']),

    save() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        if (this.sprint.id == null || this.sprint.id <= 0) {
          delete this.sprint.id;
        }
        this.$emit('save', this.sprint);
        if (this.formType === this.formTypes.create) {
          this.sprint = {};
        }
      }
    },
  },
};
</script>
