<template>
  <v-flex v-if="isAdmin && formType != ''" wrap ma-5>
    <v-autocomplete
      v-model="user.customers_id"
      :items="customers"
      item-text="name"
      item-value="id"
      label="Client"
      :error-messages="customersErrors"
      :disabled="loading"
    />
    <v-text-field
      v-model="user.firstname"
      label="Prénom"
      :error-messages="firstnameErrors"
      :disabled="loading"
    />
    <v-text-field
      v-model="user.lastname"
      label="Nom"
      :error-messages="lastnameErrors"
      :disabled="loading"
    />
    <v-text-field
      v-model="user.email"
      label="Email"
      type="email"
      :error-messages="emailErrors"
      :disabled="loading"
    />
    <v-autocomplete
      v-model="user.roles_id"
      :items="roles"
      item-text="name"
      item-value="id"
      label="Rôle"
      :error-messages="rolesErrors"
      :disabled="loading"
    />
    <v-text-field
      v-if="formType == formTypes.create"
      v-model="user.password"
      label="Mot de passe"
      :disabled="loading"
    />
    <!-- :error-messages="passwordErrors" -->
    <v-flex>
      <v-flex left>
        <v-btn center class="quaternary" @click="$router.go(-1)">
          <v-icon>mdi-arrow-left</v-icon>
          <v-flex ml-2 pa-0>
            Retour
          </v-flex>
        </v-btn>
      </v-flex>
      <v-flex right>
        <v-btn
          :disabled="!isAdmin || $v.$invalid || loading"
          center
          class="success"
          @click="save()"
        >
          <v-icon>mdi-content-save-outline</v-icon>
          <v-flex ml-2 pa-0>
            Sauvegarder
          </v-flex>
        </v-btn>
      </v-flex>
    </v-flex>
  </v-flex>
  <v-flex v-else wrap ma-5>
    Aucun formulaire
  </v-flex>
</template>

<script>
import { required, numeric, email } from 'vuelidate/lib/validators';
import { mapGetters, mapActions, mapMutations } from 'vuex';
// import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'UserForm',

  props: {
    user: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    formType: '',
    formTypes: {
      edit: 'EDIT',
      create: 'CREATE',
    },
    init: false,
  }),

  validations: {
    user: {
      customers_id: {
        required,
        number: {
          numeric,
        },
      },
      firstname: {
        required,
      },
      lastname: {
        required,
      },
      email: {
        required,
        email,
      },
      roles_id: {
        required,
        number: {
          numeric,
        },
      }, // ,
      // password: {
      //   required,
      //   minLength: minLength(8)
      // }
    },
  },

  computed: {
    ...mapGetters('customer', ['getCustomers']),
    ...mapGetters('user', ['getScopeUser']),

    customers() {
      if (this.isAdmin) {
        return this.getCustomers();
      } else {
        return this.getCustomers().filter((item) => {
          return item.id === this.getScopeUser().customers_id;
        });
      }
    },

    roles() {
      let roles = [
        {
          id: 2,
          name: 'Utilisateur',
        },
      ];
      if (this.isAdmin) {
        roles.push({
          id: 1,
          name: 'Administrateur',
        });
      }
      return roles;
    },
    customersErrors() {
      const errors = [];
      if (!this.$v.user.customers_id.$dirty) return errors;
      !this.$v.user.customers_id.required &&
        errors.push('Le client est obligatoire');
      !this.$v.user.customers_id.number.numeric &&
        errors.push('Le client est obligatoire');
      return errors;
    },

    firstnameErrors() {
      const errors = [];
      if (!this.$v.user.firstname.$dirty) return errors;
      !this.$v.user.firstname.required &&
        errors.push('Le prénom est obligatoire');
      return errors;
    },

    lastnameErrors() {
      const errors = [];
      if (!this.$v.user.lastname.$dirty) return errors;
      !this.$v.user.lastname.required && errors.push('Le nom est obligatoire');
      return errors;
    },

    emailErrors() {
      const errors = [];
      if (!this.$v.user.email.$dirty) return errors;
      !this.$v.user.email.required &&
        errors.push("L'adresse mail est obligatoire");
      !this.$v.user.email.email &&
        errors.push('Entrez une adresse email valide');
      return errors;
    },

    rolesErrors() {
      const errors = [];
      if (!this.$v.user.roles_id.$dirty) return errors;
      !this.$v.user.roles_id.required && errors.push('Le rôle est obligatoire');
      !this.$v.user.roles_id.number.numeric &&
        errors.push('Le rôle est obligatoire');
      return errors;
    },

    passwordErrors() {
      const errors = [];
      if (this.formType === this.formTypes.create) {
        if (!this.$v.user.password.$dirty) return errors;
        !this.$v.user.password.required &&
          errors.push('Le mot de passe est obligatoire');
        !this.$v.user.password.minLength &&
          errors.push('Le mot de passe doit contenir 8 caractères minimum');
      }
      return errors;
    },
  },

  mounted() {
    if (this.$route.params.id > 0) {
      this.formType = this.formTypes.edit;
      this.user.id = parseInt(this.user.id || this.$route.params.id);
    } else {
      this.formType = this.formTypes.create;
      this.user.roles_id = 2;
    }
    this.showLoader();
    this.get_customers()
      .then((response) => {
        console.log('Customers OK', response);
      })
      .catch((error) => {
        console.log('Erreur', error);
        this.snack({
          position: 'bottom',
          align: 'center',
          color: 'error',
          text: 'Obtention des clients impossible : ' + error.message,
        });
      })
      .finally(() => {
        this.showLoader();
      });
  },

  methods: {
    ...mapActions('customer', ['get_customers']),
    ...mapMutations('app', ['showLoader']),

    save() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.user.id == null || this.user.id <= 0) {
          delete this.user.id;
        }
        this.$emit('save', this.user);
        if (this.formType === this.formTypes.create) {
          this.user = {};
        }
      }
    },
  },
};
</script>
