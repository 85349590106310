<template>
  <v-flex>
    <v-card>
      <v-card-title>
        Liste des User Stories
        <v-spacer></v-spacer>
        <v-text-field
          v-if="canSearch"
          v-model="search"
          append-icon="mdi-magnify"
          label="Recherche rapide"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :pagination.sync="pagination"
        :search="search"
      >
        <template slot="headerCell" slot-scope="{ header }">
          <span class="font-weight-light text--darken-3">
            {{ header.text }}
          </span>
        </template>
        <template slot="items" slot-scope="{ item }">
          <td class="text-xs-left pointer" @click="$emit('click:row', item.id)">
            {{ item.name }}
            <!-- <small> #{{ item.id }} </small> -->
          </td>
          <td class="text-xs-left pointer" @click="$emit('click:row', item.id)">
            {{
              item.nb_user_acceptance_tests > 0
                ? `${item.nb_user_acceptance_tests_done} / ${item.nb_user_acceptance_tests}`
                : 'Aucun'
            }}
          </td>
          <td class="text-xs-left pointer" @click="$emit('click:row', item.id)">
            <!-- :question="item.nb_user_acceptance_tests_question" -->
            <ui-ProgressCircle
              :done="item.nb_user_acceptance_tests_done"
              :total="item.nb_user_acceptance_tests"
            />
          </td>
          <td class="text-xs-left pointer" @click="$emit('click:row', item.id)">
            {{
              new Date(item.created_at.replace(/ /g, 'T')).toLocaleDateString(
                'fr-FR',
                {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                }
              )
            }}
          </td>
          <td
            v-if="headers.filter((e) => e.text === 'Action').length"
            class="text-xs-left pointer'"
          >
            <v-btn
              :disabled="
                !isAdmin &&
                  !datas
                    .map((item) => item.users_id)
                    .includes(getConnectedUser.id)
              "
              class="mr-1"
              text
              icon
              @click="$emit('click:edit', item.id)"
            >
              <v-icon color="info">
                mdi-circle-edit-outline
              </v-icon>
            </v-btn>
            <v-btn
              :disabled="
                !isAdmin &&
                  !datas
                    .map((item) => item.users_id)
                    .includes(getConnectedUser.id)
              "
              class="ml-1"
              text
              icon
              @click="$emit('click:delete', item.id)"
            >
              <v-icon color="error">
                mdi-delete-outline
              </v-icon>
            </v-btn>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-flex>
</template>

<script>
import RoleMixin from '@/mixins/RoleMixin';

export default {
  mixins: [RoleMixin],

  props: {
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    canSearch: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    pagination: {
      sortBy: 'created_at',
      descending: false,
      rowsPerPage: 25, // -1 for All",
    },
    search: '',
  }),

  computed: {
    headers() {
      return this.$store.getters['app/getUserStoryHeaders']() || [];
    },
  },
};
</script>
