import { set } from '@/utils/vuex'

export default {
  SET_USER_ACCEPTANCE_TEST_UAT_VALIDATIONS: set('userAcceptanceTestUatValidations'),
  SET_UAT_VALIDATIONS: set('uatValidations'),
  SET_UAT_VALIDATION: set('uatValidation'),
  DELETE_UAT_VALIDATION: (state, uatValidationsId) => {
    state.userAcceptanceTestUatValidations = state.userAcceptanceTestUatValidations.filter(item => { return item.id !== uatValidationsId })
    state.uatValidations = state.uatValidations.filter(item => { return item.id !== uatValidationsId })
    state.uatValidation = state.uatValidation.id !== uatValidationsId ? state.uatValidation : {}
  },
  ADD_UAT_VALIDATION: (state, uatValidations) => {
    let index = state.userAcceptanceTestUatValidations.map(item => item.id).indexOf(uatValidations.id)
    index >= 0 ? state.userAcceptanceTestUatValidations[index] = uatValidations : state.userAcceptanceTestUatValidations.push(uatValidations)
    index = state.uatValidations.map(item => item.id).indexOf(uatValidations.id)
    index >= 0 ? state.uatValidations[index] = uatValidations : state.uatValidations.push(uatValidations)
    state.uatValidation = uatValidations
  }
}
