export default {
  computed: {
    getConnectedUser() {
      return JSON.parse(localStorage.getItem('user'));
    },

    getRole() {
      let user = this.getConnectedUser;
      if (user) {
        return parseInt(user.roles.id);
      } else {
        return null;
      }
    },

    isAdmin() {
      /* Admin */
      if (this.getRole && this.getRole === 1) {
        return true;
      } else {
        return false;
      }
    },

    /* User */
    isUser() {
      if (this.getRole && this.getRole === 2) {
        return true;
      } else {
        return false;
      }
    },
  },
};
