import { HTTP } from '@/plugins/axios'
import qs from 'qs'

const urlGroup = '/projects'

export const ProjectService = {
  get_customer_projects (customersId) {
    return HTTP.get(`${urlGroup}/customer/${customersId}`)
  },
  get_projects () {
    return HTTP.get(`${urlGroup}`)
  },
  get_project_by_id (projectsId) {
    return HTTP.get(`${urlGroup}/${projectsId}`)
  },
  delete_project_by_id (projectsId) {
    return HTTP.delete(`${urlGroup}/${projectsId}`)
  },
  update_project (projects) {
    return HTTP.put(`${urlGroup}/${projects.id}`, qs.stringify(projects))
  },
  create_project (projects) {
    return HTTP.post(`${urlGroup}`, qs.stringify(projects))
  }
}
