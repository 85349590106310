var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.getHeaders,"items":_vm.datas,"pagination":_vm.pagination},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"headerCell",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"font-weight-light text--darken-3",domProps:{"innerHTML":_vm._s(header.text)}})]}},{key:"items",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-xs-center",class:_vm.itemType == 'uatValidation' ? '' : 'pointer',on:{"click":function($event){return _vm.emitOnRowCLick(item.id)}}},[(_vm.itemType == 'uatValidation')?_c('p',[(item.users && item.users.is_admin)?_c('v-icon',{attrs:{"color":"primary","size":"16"}},[_vm._v(" mdi-cube ")]):_c('v-icon',{attrs:{"color":item.own_project ? 'success' : 'primary',"size":"15"}},[_vm._v(" mdi-face ")]),_vm._v(" "+_vm._s(item.users_name)+" ")],1):_c('p',[_vm._v(" "+_vm._s(item.name)+" ")])]),_c('td',{class:[
        _vm.itemType == 'userAcceptanceTest' || _vm.itemType == 'uatValidation'
          ? 'text-xs-center'
          : 'text-xs-left',
        _vm.itemType == 'uatValidation' ? '' : 'pointer' ],on:{"click":function($event){return _vm.emitOnRowCLick(item.id)}}},[(_vm.itemType == 'uatValidation')?_c('p',[_vm._v(" "+_vm._s(item.customers_name)+" ")]):(_vm.itemType == 'userAcceptanceTest')?_c('v-badge',{attrs:{"content":item[_vm.nbName],"value":item[_vm.nbName],"overlap":""}},[_c('ui-ProgressCircle',{attrs:{"is-done":item[_vm.nbName] > 0}})],1):_c('v-flex',[_vm._v(" "+_vm._s(item[_vm.nbName] > 0 ? item[_vm.nbName + '_done'] + '/' + item[_vm.nbName] : 'Aucun')+" ")])],1),(_vm.itemType == 'uatValidation')?_c('td',{class:_vm.itemType == 'uatValidation' ? '' : 'pointer'},[_vm._v(" "+_vm._s(item.comment)+" ")]):_vm._e(),_c('td',{staticClass:"text-xs-center",class:_vm.itemType == 'uatValidation' ? '' : 'pointer',on:{"click":function($event){return _vm.emitOnRowCLick(item.id)}}},[(_vm.itemType == 'uatValidation')?_c('ui-UatValidationProgressCircle',{attrs:{"done":item.is_done}}):(_vm.itemType == 'userAcceptanceTest')?_c('ui-ProgressCircle',{attrs:{"done":item.is_done,"total":2}}):_c('ui-ProgressCircle',{attrs:{"done":item[_vm.nbName + '_done'],"total":item[_vm.nbName]}})],1),_c('td',{staticClass:"text-xs-left",class:_vm.itemType == 'uatValidation' ? '' : 'pointer',on:{"click":function($event){return _vm.emitOnRowCLick(item.id)}}},[_vm._v(" "+_vm._s(item.created_at)+" ")]),(
        _vm.headers.map(function (item) { return item.text; }).includes('Action') &&
          (_vm.isAdmin ||
            _vm.datas.map(function (item) { return item.users_id; }).includes(_vm.getConnectedUsersId))
      )?_c('td',{staticClass:"text-xs-center",class:_vm.itemType == 'uatValidation' ? '' : 'pointer'},[_c('v-btn',{staticClass:"mr-1",attrs:{"disabled":!_vm.isAdmin &&
            !_vm.datas.map(function (item) { return item.users_id; }).includes(_vm.getConnectedUsersId),"text":"","icon":""},on:{"click":function($event){return _vm.emitEditOnRowCLick(item.id)}}},[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(" mdi-circle-edit-outline ")])],1),_c('v-btn',{staticClass:"ml-1",attrs:{"disabled":!_vm.isAdmin &&
            !_vm.datas.map(function (item) { return item.users_id; }).includes(_vm.getConnectedUsersId),"text":"","icon":""},on:{"click":function($event){return _vm.emitDeleteOnRowCLick(item.id)}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-delete-outline ")])],1)],1):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }