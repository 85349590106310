import { ProjectService } from '@/store/services/ProjectService'
import handleApiError from '@/helpers/handleApiError'

export default {
  get_customer_projects (context, customersId) {
    return new Promise((resolve, reject) => {
      ProjectService.get_customer_projects(customersId)
        .then(response => {
          var data = response.data
          context.commit('SET_CUSTOMER_PROJECTS', data)
          resolve(data)
        })
        .catch(error => {
          handleApiError(error, reject)
        })
    })
  },
  get_projects (context) {
    return new Promise((resolve, reject) => {
      ProjectService.get_projects()
        .then(response => {
          var data = response.data
          context.commit('SET_PROJECTS', data)
          resolve(data)
        })
        .catch(error => {
          handleApiError(error, reject)
        })
    })
  },
  get_project_by_id (context, projectsId) {
    return new Promise((resolve, reject) => {
      ProjectService.get_project_by_id(projectsId)
        .then(response => {
          var data = response.data
          context.commit('SET_PROJECT', data)
          resolve(data)
        })
        .catch(error => {
          handleApiError(error, reject)
        })
    })
  },
  delete_project_by_id (context, projectsId) {
    return new Promise((resolve, reject) => {
      ProjectService.delete_project_by_id(projectsId)
        .then(response => {
          var data = response.data
          context.commit('DELETE_PROJECT', projectsId)
          resolve(data)
        })
        .catch(error => {
          handleApiError(error, reject)
        })
    })
  },
  update_project (context, projects) {
    return new Promise((resolve, reject) => {
      ProjectService.update_project(projects)
        .then(response => {
          var data = response.data
          context.commit('ADD_PROJECT', projects)
          resolve(data)
        })
        .catch(error => {
          handleApiError(error, reject)
        })
    })
  },
  create_project (context, projects) {
    return new Promise((resolve, reject) => {
      ProjectService.create_project(projects)
        .then(response => {
          var data = response.data
          context.commit('ADD_PROJECT', projects)
          resolve(data)
        })
        .catch(error => {
          handleApiError(error, reject)
        })
    })
  }
}
