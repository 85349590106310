import { set } from '@/utils/vuex'

export default {
  SET_USER_STORIES_USER_ACCEPTANCE_TESTS: set('userStoryUserAcceptanceTests'),
  SET_USER_ACCEPTANCE_TESTS: set('userAcceptanceTests'),
  SET_USER_ACCEPTANCE_TEST: set('userAcceptanceTest'),
  DELETE_USER_ACCEPTANCE_TEST: (state, userAcceptanceTestsId) => {
    state.userStoryUserAcceptanceTests = state.userStoryUserAcceptanceTests.filter(item => { return item.id !== userAcceptanceTestsId })
    state.userAcceptanceTests = state.userAcceptanceTests.filter(item => { return item.id !== userAcceptanceTestsId })
    state.userAcceptanceTest = state.userAcceptanceTest.id !== userAcceptanceTestsId ? state.userAcceptanceTest : {}
  },
  ADD_USER_ACCEPTANCE_TEST: (state, userAcceptanceTests) => {
    let index = state.userStoryUserAcceptanceTests.map(item => item.id).indexOf(userAcceptanceTests.id)
    index >= 0 ? state.userStoryUserAcceptanceTests[index] = userAcceptanceTests : state.userStoryUserAcceptanceTests.push(userAcceptanceTests)
    index = state.userAcceptanceTests.map(item => item.id).indexOf(userAcceptanceTests.id)
    index >= 0 ? state.userAcceptanceTests[index] = userAcceptanceTests : state.userAcceptanceTests.push(userAcceptanceTests)
    state.userAcceptanceTest = userAcceptanceTests
  }
}
