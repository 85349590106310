export default {
  admin: "#EE010F",
  primary: "#222A38",
  secondary: "#292E38",
  tertiary: "#4E576A",
  quaternary: "#68748D",
  accent: "#82B1FF",
  error: "#F55A4E",
  info: "#00D3EE",
  success: "#5CB860",
  warning: "#FFA21A",
  black: "#111111",
};
