import { mapMutations } from 'vuex'

export default {
  data: () => ({
    color: null,
    top: true,
    bottom: false,
    left: false,
    right: false,
    snackbar: false,
    text: 'OK'
  }),

  methods: {
    ...mapMutations('app', ['showSnack', 'hideSnack']),

    showSnackBar (payload) {
      console.log('payload snack mixin', payload)
      this.showSnack(payload)
    },
    hideSnackBar () {
      this.hideSnack()
    },

    snack (...args) {
      console.log('SNACK', args)
      this.top = args[0]['position'] == 'top'
      this.bottom = args[0]['position'] == 'bottom'
      this.left = args[0]['align'] == 'left'
      this.right = args[0]['align'] == 'right'
      this.color = args[0]['color']

      // for (const loc of args) {
      //   this[loc] = true
      // }
      this.text = args[0]['text']
      this.snackbar = true
    }
  }
}
