<template>
  <v-footer
    id="core-footer"
    absolute
    height="70"
    class="px-4 py-0 mt-3 mb-0 mx-0"
  >
    <v-flex
      xs12
      sm6
      class="text-xs-left"
    >
      <v-icon class="mr-1">
        mdi-counter
      </v-icon>Version:<strong>{{ version }}</strong>
      <span class="ml-3">

        <v-chip
          label
          :color="color"
        >
          <v-icon
            color="white"
            class="mr-1"
          >mdi-server</v-icon>
          {{ name }}
        </v-chip>
      </span>
    </v-flex>

    <v-spacer />
    <span class="font-weight-light copyright">
      Tous droits réservés
      <v-icon
        color="tertiary"
        size="17"
      >
        mdi-cube
      </v-icon>
      <a
        href="https://www.cubecom.fr/"
        target="_blank"
      >
        Agence CUBE
      </a>
    </span>
  </v-footer>
</template>

<script>
import { VERSION } from '@/plugins/constant'
export default {
  name: 'FooterComponent',

  data: () => ({
    version: VERSION,
    host: window.location.host,
    color: '',
    name: ''
  }),

  mounted () {
    this.getColor()
  },

  methods: {
    getColor () {
      switch (this.host) {
        case 'localhost':
          this.color = 'primary'
          this.name = 'LOCAL'
          break
        case 'manager.cubecom.fr':
          this.color = 'success'
          this.name = 'PROD'
          break
        default:
          this.color = 'warning'
          this.name = 'DEV'
          break
      }
    }
  }
}
</script>

<style>
#core-footer {
  background:#FFF;
  z-index: 0;
}
</style>
