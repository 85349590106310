export default {
  methods: {
    showErrorDialog (title, content) {
      console.log('in show error dialog', title, content)
      this.$store.commit('SHOW_MODAL', { title, content })
    },
    hideErrorDialog () {
      this.$store.commit('HIDE_MODAL')
    }
  }
}
