export default {
  getUserAcceptanceTestUatValidations: state => () => {
    return state.userAcceptanceTestUatValidations
  },
  getUatValidations: state => () => {
    return state.uatValidations
  },
  getUatValidation: state => () => {
    return state.uatValidation
  }
}
