<template>
  <small class="mx-2 body-2 text-uppercase identifier-color">
    {{ `${prefix}-${id}` }}
  </small>
</template>
<script>
export default {
  name: 'Identifier',

  props: {
    prefix: {
      type: String,
      required: true
    },

    id: {
      type: Number,
      required: true
    }
  }
}
</script>
<style>
.identifier-color {
    color: rgba(0,0,0,0.5)
}
</style>
