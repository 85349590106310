export default {
  getDashboardIds: (state) => () => {
    return state.dashboardIds;
  },
  getDashboardPath: (state) => () => {
    let lastItems = Object.keys(state.dashboardIds).filter(
      (item) => state.dashboardIds[item] > 0
    );
    let currentItem = Object.keys(state.dashboardIds).filter(
      (item) => !state.dashboardIds[item]
    )[0];
    return [
      ...lastItems.map((item) => {
        return {
          item: item,
          name: state.dashboardPath[item],
          id: state.dashboardIds[item].toString(),
        };
      }),
      { item: currentItem, name: state.dashboardPath[currentItem], id: null },
    ];
  },
  getRole: (state) => () => {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      return parseInt(user.roles.id);
    } else {
      return null;
    }
  },
  getProjectHeaders: (state, getters) => () => {
    let headers = [
      {
        sortable: true,
        text: 'Projet',
        value: 'name',
        align: 'left',
      },
      {
        sortable: true,
        text: 'Sprints',
        value: 'nb_sprints_done',
        align: 'left',
      },
      {
        sortable: false,
        text: 'Etat',
        align: 'left',
      },
      {
        sortable: true,
        text: 'Date de création',
        value: 'created_at',
        align: 'left',
      },
    ];
    if (getters.getRole() <= 1) {
      headers.push({
        sortable: false,
        text: 'Action',
        align: 'left',
      });
    }
    return headers;
  },
  getSprintHeaders: (state, getters) => () => {
    let headers = [
      {
        sortable: true,
        text: 'Sprint',
        value: 'name',
        align: 'left',
      },
      {
        sortable: true,
        text: 'User Stories',
        value: 'nb_user_stories_done',
        align: 'left',
      },
      {
        sortable: false,
        text: 'Etat',
        align: 'left',
      },
      {
        sortable: true,
        text: 'Date de création',
        value: 'created_at',
        align: 'left',
      },
    ];
    if (getters.getRole() <= 1) {
      headers.push({
        sortable: false,
        text: 'Action',
        align: 'left',
      });
    }
    return headers;
  },
  getUserStoryHeaders: (state, getters) => () => {
    let headers = [
      {
        sortable: true,
        text: 'User Story',
        value: 'name',
        align: 'left',
      },
      {
        sortable: true,
        text: 'UAT',
        value: 'nb_user_acceptance_tests_done',
        align: 'left',
      },
      {
        sortable: false,
        text: 'Etat',
        align: 'left',
      },
      {
        sortable: true,
        text: 'Date de création',
        value: 'created_at',
        align: 'left',
      },
    ];
    if (getters.getRole() <= 1) {
      headers.push({
        sortable: false,
        text: 'Action',
        align: 'left',
      });
    }
    return headers;
  },
  getUserAcceptanceTestHeaders: (state, getters) => () => {
    let headers = [
      {
        sortable: true,
        text: 'UAT',
        value: 'name',
        align: 'left',
      },
      {
        sortable: true,
        text: 'Nombres de messages',
        value: 'nb_uat_validations',
        align: 'left',
      },
      {
        sortable: false,
        text: 'Etat',
        align: 'left',
      },
      {
        sortable: true,
        text: 'Date de création',
        value: 'created_at',
        align: 'left',
      },
    ];
    if (getters.getRole() <= 1) {
      headers.push({
        sortable: false,
        text: 'Action',
        align: 'left',
      });
    }
    return headers;
  },
  getUatValidationHeaders: (state, getters) => () => {
    let headers = [
      {
        sortable: true,
        text: 'Utilisateur',
        value: 'users_name',
        align: 'left',
      },
      {
        sortable: true,
        text: 'Client',
        value: 'customers_name',
        align: 'left',
      },
      {
        sortable: true,
        text: 'Commentaire',
        value: 'comment',
        align: 'left',
      },
      {
        sortable: false,
        text: 'Etat',
        align: 'left',
      },
      {
        sortable: true,
        text: 'Date de création',
        value: 'created_at',
        align: 'left',
      },
    ];
    if (getters.getRole() <= 1) {
      headers.push({
        sortable: false,
        text: 'Action',
        align: 'left',
      });
    }
    return headers;
  },
  getCustomerHeaders: (state, getters) => () => {
    let headers = [
      {
        sortable: true,
        text: 'Client',
        value: 'name',
        align: 'left',
      },
      {
        sortable: true,
        text: "Nombre d'utilisateurs",
        value: 'nb_users',
        align: 'left',
      },
      {
        sortable: true,
        text: 'Nombre de projets',
        value: 'nb_projects',
        align: 'left',
      },
      {
        sortable: true,
        text: 'Date de création',
        value: 'created_at',
        align: 'left',
      },
    ];
    if (getters.getRole() <= 1) {
      headers.push({
        sortable: false,
        text: 'Action',
        align: 'left',
      });
    }
    return headers;
  },
  getUserHeaders: (state, getters) => () => {
    let headers = [
      {
        sortable: true,
        text: 'Utilisateur',
        value: 'name',
        align: 'left',
      },
      {
        sortable: true,
        text: 'Client',
        value: 'customers_name',
        align: 'left',
      },
      {
        sortable: true,
        text: 'Actif',
        value: 'is_active',
        align: 'left',
      },
      {
        sortable: false,
        text: 'Rôle',
        value: 'roles_label',
        align: 'left',
      },
      {
        sortable: true,
        text: 'Email',
        value: 'email',
        align: 'left',
      },
      {
        sortable: true,
        text: 'Date de création',
        value: 'created_at',
        align: 'left',
      },
    ];
    if (getters.getRole() <= 1) {
      headers.push({
        sortable: false,
        text: 'Action',
        align: 'left',
      });
    }
    return headers;
  },
};
