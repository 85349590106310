export default {
  dashboardIds: {
    project: null,
    sprint: null,
    userStory: null,
    userAcceptanceTest: null,
    uatValidation: null
  },
  dashboardPath: {
    project: 'Projets',
    sprint: 'Sprints',
    userStory: 'User Stories',
    userAcceptanceTest: 'User Acceptance Tests',
    uatValidation: 'Uat Validations'
  },
  drawer: null,
  color: 'quaternary',
  sidebarBackgroundColor: 'rgba(255, 255, 255, 0.4)',
  loginBackgroundColor: 'rgba(0, 0, 0, 0.8)',
  logo: './img/cube-logo.png',
  modalVisible: false,
  modalTitle: '',
  modalContent: '',
  loading: false,
  snackColor: '',
  snackText: '',
  snackVisible: false
}
