import { set, toggle } from '@/utils/vuex';
// import {mapState} from 'vuex'

export default {
  // save or reset the path of the dashboard list
  setDashboardIds: (state, payload) => {
    state.dashboardIds[payload.item] = payload.id;
  },
  resetDashboardIds: (state) => {
    state.dashboardIds = {
      project: null,
      sprint: null,
      userStory: null,
      userAcceptanceTest: null,
      uatValidation: null,
    };
  },
  // app use
  setDrawer: set('drawer'),
  setImage: set('image'),
  setColor: set('color'),
  toggleDrawer: toggle('drawer'),
  // showModal: {
  //   toggle('modalVisible')
  //   set('modalTitle', payload.title)
  //   set('modalContent', payload.content)
  // },
  // hideModal: {
  //   toggle('modalVisible')
  //   set('modalTitle', '')
  //   set('modalContent', '')
  // },
  showLoader: toggle('loading'),
  hideLoader: toggle('loading'),
  showSnack: (state, payload) => {
    console.log('show snack mut', payload);
    // toggle('snackVisible')
    // set('snackColor', payload.color)
    // set('snackText', payload.msg)
    state.app.snackVisible = true;
    state.app.snackColor = payload.color;
    state.app.snackText = payload.text;
    console.log('finito');
  },
  hideSnack: () => {
    toggle('snackVisible');
    set('snackColor', '');
    set('snackText', '');
  },
};
