<template>
  <v-flex v-if="loading" xs12 md4 offset-md4 class="my-4">
    <v-dialog v-model="loading" persistent width="200">
      <v-card color="primary" dark>
        <v-card-text class="text-md-center">
          <v-progress-circular indeterminate size="100" width="6" color="white">
            <small>Chargement</small>
          </v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-flex>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style></style>
