export default {
  getCustomerProjects: state => () => {
    return state.customerProjects
  },
  getProjects: state => () => {
    return state.projects
  },
  getProject: state => () => {
    return state.project
  }
}
