<template>
  <material-card
    v-bind="$attrs"
    class="v-card--material-stats"
    v-on="$listeners"
  >
    <v-card
      slot="offset"
      :class="`elevation-${elevation}`"
      :color="color"
      class="pa-4"
      dark
    >
      <v-icon
        size="50"
      >
        {{ icon }}
      </v-icon>
    </v-card>
    <div class="text-xs-right">
      <p
        class="category grey--text font-weight-light"
        v-html="title"
      />
      <a
        v-if="typeof (linkTo) !== 'undefined' && linkTo !== ''"
        :href="getLink"
        class="font-weight-bold subheading v-btn v-btn--small black"
        target="_blank"
      >
        <v-icon class="mr-1">
          mdi-file-pdf
        </v-icon>
        {{ value }}
      </a>
      <!-- <v-btn
        v-if="typeof (linkTo) !== 'undefined' && linkTo !== ''"
        class="font-weight-bold subheading"
        color="black"
        small
        @click="goto"
      >

        {{ value }}
      </v-btn> -->
      <h3
        v-else
        class="title display-1 font-weight-light"
      >
        {{ value }} <small>{{ smallValue }}</small>
      </h3>
    </div>

    <template slot="actions">
      <v-icon
        :color="subIconColor"
        size="20"
        class="mr-2"
      >
        {{ subIcon }}
      </v-icon>
      <span
        :class="getSubTextColor"
        class="caption font-weight-light"
        v-html="subText"
      />
    </template>
  </material-card>
</template>

<script>
import Card from './Card'
import { ENV } from '@/plugins/constant'

export default {
  inheritAttrs: false,

  props: {
    ...Card.props,
    icon: {
      type: String,
      required: true
    },
    subIcon: {
      type: String,
      default: undefined
    },
    subIconColor: {
      type: String,
      default: undefined
    },
    subTextColor: {
      type: String,
      default: undefined
    },
    subText: {
      type: String,
      default: undefined
    },
    title: {
      type: String,
      default: undefined
    },
    value: {
      type: String,
      default: undefined
    },
    smallValue: {
      type: String,
      default: undefined
    },
    linkTo: {
      type: String,
      default: undefined
    }
  },

  computed: {
    getSubTextColor () {
      return 'text-' + this.subTextColor
    },

    getLink () {
      return `${ENV}/files/${this.linkTo}`
    }
  },

  mounted () {
    if (typeof (this.linkTo) !== 'undefined' && this.linkTo !== '') {
      console.log('LINKTO:', `${ENV}/files/${this.linkTo}`)
    }
  },

  methods: {
    // goto () {
    //   let route = this.$router.resolve({ path: `${ENV}/files/${this.linkTo}` })
    //   console.log('download file', route)
    //   window.open(route.href, '_blank')
    // }
  }
}
</script>

<style lang="scss">
.v-card--material-stats {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  .v-offset {
    display: inline-block;
    flex: 0 1;
    margin-top: 0;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 0 !important;
    max-width: auto;
    padding: 0 16px;
  }

  .v-card {
    border-radius: 4px;
    flex: 0 1 auto;
  }

  .v-card__text {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
  }

  .v-card__actions {
    flex: 1 0 100%;
  }

  .v-card__text .v-btn{margin:0}
}
</style>
