import { set } from '@/utils/vuex'

export default {
  SET_CONTACT: set('contact'),
  SET_CONTACTS: set('contacts'),
  DELETE_CONTACT: (state, contactsId) => {
    state.contacts = state.contacts.filter(item => { return item.id !== contactsId })
    state.contact = state.contact.id !== contactsId ? state.contact : {}
  },
  ADD_CONTACT: (state, contacts) => {
    let index = state.contacts.map(item => item.id).indexOf(contacts.id)
    index >= 0 ? state.contacts[index] = contacts : state.contacts.push(contacts)
    state.contact = contacts
  }
}
