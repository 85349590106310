<template>
  <v-flex>
    <v-card>
      <v-card-title>
        Liste des clients
        <v-spacer></v-spacer>
        <v-text-field
          v-if="canSearch"
          v-model="search"
          append-icon="mdi-magnify"
          label="Recherche rapide"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="customers"
        :loading="loading"
        :pagination.sync="pagination"
        :search="search"
      >
        <template slot="headerCell" slot-scope="{ header }">
          <span class="font-weight-light text--darken-3">
            {{ header.text }}
          </span>
        </template>
        <template slot="items" slot-scope="{ item }">
          <td class="text-xs-center">
            <v-icon
              v-if="getConnectedCustomersId == item.id"
              size="17"
              color="quaternary"
            >
              mdi-map-marker
            </v-icon>
            {{ item.name }}
          </td>
          <td class="text-xs-left">
            <v-icon v-if="item.nb_users > 1" color="quaternary" size="16">
              mdi-account-group
            </v-icon>
            <v-icon v-else-if="item.nb_users >= 1" color="quaternary" size="16">
              mdi-account
            </v-icon>
            <v-icon v-else color="quaternary" size="16">
              mdi-account-off
            </v-icon>
            {{ item.nb_users || 0 }} Utilisateurs
          </td>
          <td class="text-xs-left">
            <v-icon v-if="item.nb_projects > 0" color="quaternary" size="16">
              mdi-clipboard-text
            </v-icon>
            <v-icon v-else color="quaternary" size="16">
              mdi-clipboard
            </v-icon>
            {{ item.nb_projects || 0 }} Projets
          </td>
          <td class="text-xs-left">
            {{ item.created_at }}
          </td>
          <td
            v-if="
              headers.map((item) => item.text).includes('Action') && isAdmin
            "
            class="text-xs-center"
          >
            <v-btn
              v-if="isAdmin"
              :disabled="!isAdmin"
              class="mr-1"
              text
              icon
              @click="emitEditOnRowCLick(item.id)"
            >
              <v-icon color="info">
                mdi-circle-edit-outline
              </v-icon>
            </v-btn>
            <v-btn
              v-if="isAdmin"
              :disabled="!isAdmin || item.id === getConnectedUser.customers_id"
              class="ml-1"
              text
              icon
              @click="emitDeleteOnRowCLick(item.id)"
            >
              <v-icon color="error">
                mdi-delete-outline
              </v-icon>
            </v-btn>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-flex>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DataTableCustomer',

  props: {
    customers: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    canSearch: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    pagination: {
      sortBy: 'created_at',
      descending: false,
      rowsPerPage: 25, // -1 for All",
    },
    search: '',
  }),

  computed: {
    ...mapGetters('user', ['getScopeUser']),
    ...mapGetters('app', ['getCustomerHeaders']),

    headers() {
      return this.getCustomerHeaders();
    },

    getConnectedCustomersId() {
      return this.getScopeUser().customers_id || 0;
    },
  },

  methods: {
    emitOnRowCLick(id) {
      this.$emit('onRowCLick', { id: id });
    },
    emitEditOnRowCLick(id) {
      this.$emit('editRow', { id: id });
    },
    emitDeleteOnRowCLick(id) {
      if (id !== this.getConnectedUser.customers_id) {
        this.$emit('deleteRow', { id: id });
      }
    },
  },
};
</script>
<style></style>
