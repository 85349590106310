<template>
  <v-flex>
    <v-card
      class="mt-2"
      flat
    >
      <v-card-title v-if="title">
        <h2 class="headline font-weight-thin text-uppercase">
          {{ title }}
        </h2>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-layout>
          <v-flex
            v-if="!loading && filter"
            xs12
            md6
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-search"
              label="Filtre"
              single-line
              hide-details
              outline
              class="mb-2 mx-2 text-capitalize"
            />
          </v-flex>

          <v-flex
            xs12
            md6
            class="text-xs-right"
          >
            <slot name="button" />
          </v-flex>
        </v-layout>

        <v-flex
          v-if="!loading && files.length"
          xs12
        >
          <v-data-table
            :headers="tableHeaders"
            :headers-length="headerlength"
            :search="search"
            :items="files"
            class="elevation-1 tablefile"
          >
            <template
              slot="headers"
              slot-scope="props"
            >
              <tr>
                <th />
                <th
                  v-for="header in props.headers"
                  :key="header.text"
                  :class="['column px-2', 'text-xs-' + header.align]"
                >
                  {{ header.text }}
                </th>
                <th>Actions</th>
              </tr>
            </template>
            <template
              slot="items"
              slot-scope="props"
            >
              <tr>
                <td
                  class="text-xs-center"
                  width="30px"
                >
                  <img
                    v-if="isImg(props.item)"
                    :src="imgSrc(props.item.token)"
                    :width="width_"
                  >
                  <v-icon
                    v-else
                    small
                  >
                    mdi-note
                  </v-icon>
                </td>
                <td
                  class="text-xs-left text-truncate body-1"
                  style="max-width:150px"
                >
                  <span v-html="formatLink(props.item.id)" />
                </td>
                <td class="text-xs-left font-weight-thin caption">
                  {{ props.item.size | formatSize }}
                </td>
                <td class="text-xs-center px-0">
                  <v-btn
                    color="warning"
                    flat
                    fab
                    small
                    @click.prevent="confirmRemoveFile(props.item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
            <template slot="no-data">
              <EmptySlate
                small
                title="Aucun document"
                icon="note"
                class="my-4"
              />
              <material-notification
                class="mb-3"
                color="info"
                dismissible
              >
                <strong>Aucun fichier</strong>
              </material-notification>
            </template>
          </v-data-table>
          <span v-if="errortxt.length">
            <strong>Erreur :</strong>
            {{ errortxt }}
          </span>
        </v-flex>
      </v-card-text>
    </v-card>
    <!-- Confirm delete -->
    <v-dialog
      v-model="dialog_del"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <h2>Suppression du fichier</h2>
        </v-card-title>

        <v-card-text class="text-xs-left">
          <strong>Confirmez-vous</strong> la suppression du fichier ?
          <br>
        </v-card-text>

        <v-card-actions>
          <v-btn
            flat
            @click="dialog_del = false"
          >
            <v-icon class="mr-1">
              mdi-cancel
            </v-icon>Annuler
          </v-btn>
          <v-spacer />
          <v-btn
            flat
            color="warning"
            @click="removeFile"
          >
            <v-icon class="mr-1">
              mdi-delete
            </v-icon>Oui supprimer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-flex>
</template>

<script>
import { ENV } from '@/plugins/constant'
export default {
  name: 'FileViewer',

  filters: {
    formatSize: function (size) {
      if (size > 1024 * 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
      } else if (size > 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
      } else if (size > 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + ' MB'
      } else if (size > 1024) {
        return (size / 1024).toFixed(2) + ' KB'
      }
      return size.toString() + ' B'
    }
  },

  props: {
    files: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    },

    filter: {
      type: Boolean,
      required: false,
      default: false
    },

    title: {
      type: String,
      required: false
    },

    width: {
      type: Number,
      required: false
    },

    heigh: {
      type: Number,
      required: false
    },
    /* Predefined size : thumbs, medium, large OR list */
    format: {
      type: String,
      required: false,
      default: 'list'
    },

    download: {
      type: Boolean,
      required: false,
      default: false
    },
    manage: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    search: '',
    loading: false,
    filedata: null,
    dialog_del: false,
    file_to_delete: {},
    errortxt: '',
    heigh_: 0,
    width_: 0,
    headerlength: 4,
    tableHeaders: [
      {
        text: 'Nom',
        align: 'left',
        value: 'name'
      },
      {
        text: 'Taille',
        align: 'left',
        value: 'size'
      }
    ]
  }),

  mounted () {
    // loop on the files array to get the uri
    // console.log('Files:',this.files)
    // this.getFile(this.token)

    // console.log(this.format)
    switch (this.format) {
      case 'list':
        this.width_ = '20px'
        this.heigh_ = 'auto'
        break
      case 'thumb':
        this.width_ = '80px'
        this.heigh_ = 'auto'
        break
      case 'medium':
        this.width_ = '200px'
        this.heigh_ = 'auto'
        break
      case 'large':
        this.width_ = '500px'
        this.heigh_ = 'auto'
        break
    }
    if (this.heigh) {
      this.heigh_ = this.heigh + 'px'
    }
    if (this.width) {
      this.width_ = this.width + 'px'
    }
  },

  methods: {
    formatLink (id) {
      let file = this.files.find(elem => {
        return elem.id == id
      })
      // console.log(file)
      return (
        '<a href="' +
        ENV +
        '/files/' +
        file.token +
        '" target="_blank">' +
        file.name +
        '</a>'
      )
    },

    imgSrc (token) {
      return ENV + '/files/' + token
    },

    getFile (token) {
      this.loading = true
      // console.log("loading")
      // call API to get file with token
      this.$store
        .dispatch('get_file', token)
        .then(response => {
          // console.log('viewer:',response)
          // let img = document.createElement('img')
          // img.src = 'data:image/jpegbase64,' + this.hexToBase64(response)
          // console.log(response.headers['content-type'])
          let contentType = response.headers['content-type']
          if (contentType == 'image/jpeg') {
            this.filedata = this.imgSrc(token)
          }
          // this.filedata = 'data:image/jpegbase64,' + this.hexToBase64(response.data)
          // this.filedata = 'data:image/jpegbase64,' + btoa(response.data)
          // this.filedata = 'data:image/jpegbase64,' + response.data
        })
        .catch(e => {
          // display Error
          console.log('ERREUR', e)
          if (e.status == 401) {
            this.errortxt =
              "<strong>Erreur lors de la récupération de l'image</strong><br/>Vous n'avez pas les droits."
          } else {
            this.errortxt = e.status + ' ' + e.statusText
          }
        })
        .then(() => {
          console.log('end loading')
          this.loading = false
        })
    },

    isImg (file) {
      let extension = ['image/jpeg', 'image/png', 'image/gif', 'image/webp']
      let index = extension.indexOf(file.mimetype)
      return index != -1
    },

    confirmRemoveFile (file) {
      this.dialog_del = true
      this.file_to_delete = file
      console.log(file)
    },

    removeFile () {
      this.loading = true
      this.$store
        .dispatch('remove_file', this.file_to_delete)
        .then(response => {
          console.log('delete OK')
          // emit to the parent in order to delete the file in the object
          this.$emit('fileDeleted', this.file_to_delete)
        })
        .catch(e => {
          // display Error
          console.log('ERREUR', e)
          if (e.status == 401) {
            this.errortxt =
              "<strong>Erreur lors de la suppression de l'image</strong><br/>Vous n'avez pas les droits."
          } else {
            this.errortxt = e.status + ' ' + e.statusText
          }
        })
        .then(() => {
          console.log('end delete')
          this.loading = false
          this.dialog_del = false
        })
    }
  }
}
</script>
<style scoped>
.tablefile table.v-table tbody td:first-child,
.tablefile table.v-table tbody td:not(:first-child),
.tablefile table.v-table tbody th:first-child,
.tablefile table.v-table tbody th:not(:first-child),
.tablefile table.v-table thead td:first-child,
.tablefile table.v-table thead td:not(:first-child),
.tablefile table.v-table thead th:first-child,
.tablefile table.v-table thead th:not(:first-child) {
  padding: 0 2px;
}
</style>
