<template>
  <v-content>
    <div id="core-view">
      <v-fade-transition mode="out-in">
        <router-view class="py-0" />
      </v-fade-transition>
      <v-btn
        v-if="scrollPage"
        class="elevation-2"
        color="black"
        dark
        fab
        fixed
        style="bottom: 20px;"
        bottom
        @click="goTop"
      >
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
    </div>
    <ui-loader :loading="load" />
    <core-footer v-if="!isLoginPage" />
  </v-content>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: `Manager ${this.$route.name ? `- ${this.$route.name}` : ''}`,
    };
  },

  data: () => ({
    displayBtn: false,
  }),

  computed: {
    load() {
      return this.$store.state.app.loading;
    },

    isLoginPage() {
      return (
        this.$route.name === 'Connexion' ||
        this.$route.name === 'Nous Contacter'
      );
    },

    scrollPage() {
      return this.displayBtn;
    },
  },

  created() {
    window.addEventListener('scroll', this.handleScroll);
  },

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    handleScroll() {
      if (window.scrollY > 20 || document.documentElement.scrollTop > 20) {
        this.displayBtn = true;
      } else {
        this.displayBtn = false;
      }
    },

    goTop() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      this.displayBtn = false;
    },
  },
};
</script>

<style>
html {
  scroll-behavior: smooth;
}
</style>
