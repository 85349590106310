<template>
  <v-dialog
    v-model="show"
    class="text-xs-center"
    max-width="500px"
    width="500"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        color="deep-purple"
        flat
        v-on="on"
      >
        {{ buttonLabel }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title
        v-if="title"
        class="headline grey lighten-2"
        primary-title
      >
        {{ title }}
      </v-card-title>
      <v-card-text v-html="content" />

      <v-card-actions>
        <v-spacer />
        <v-btn
          flat
          @click="close"
        >
          <v-icon v-if="closeButtonIcon">
            {{ closeButtonIcon }}
          </v-icon>{{ closeButtonLabel }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    buttonLabel: {
      type: String,
      required: true
    },
    closeButtonLabel: {
      type: String,
      required: true
    },
    closeButtonIcon: {
      type: String,
      required: false
    },
    callback: {
      type: Function,
      required: false
    }
  },

  data: () => ({
    show: false
  }),

  methods: {
    close () {
      this.show = false
      if (this.callback) this.callback()
    }
  }
}
</script>

<style></style>
