<template>
  <v-flex v-if="isAdmin && formType != ''" wrap ma-5>
    <v-autocomplete
      v-model="project.customers_id"
      :items="customers"
      item-text="name"
      item-value="id"
      label="Client"
      :error-messages="customersErrors"
      :disabled="loading"
    />
    <v-text-field
      v-model="project.name"
      label="Nom du projet"
      :error-messages="nameErrors"
      :disabled="loading"
    />
    <v-flex>
      <v-flex left>
        <v-btn center class="quaternary" @click="$router.go(-1)">
          <v-icon>mdi-arrow-left</v-icon>
          <v-flex ml-2 pa-0>
            Retour
          </v-flex>
        </v-btn>
      </v-flex>
      <v-flex right>
        <v-btn
          :disabled="!isAdmin || $v.$invalid || loading"
          center
          class="success"
          @click="save()"
        >
          <v-icon>mdi-content-save-outline</v-icon>
          <v-flex ml-2 pa-0>
            Sauvegarder
          </v-flex>
        </v-btn>
      </v-flex>
    </v-flex>
  </v-flex>
  <v-flex v-else wrap ma-5>
    Aucun formulaire
  </v-flex>
</template>

<script>
import { required, numeric } from 'vuelidate/lib/validators';
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: 'ProjectForm',

  props: {
    project: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    formType: '',
    formTypes: {
      edit: 'EDIT',
      create: 'CREATE',
    },
  }),

  validations: {
    project: {
      name: {
        required,
      },
      customers_id: {
        required,
        number: {
          numeric,
        },
      },
    },
  },

  computed: {
    ...mapGetters('customer', ['getCustomers']),
    ...mapGetters('user', ['getScopeUser']),

    defaultCustomersId() {
      return this.getScopeUser().customers_id;
    },

    customers() {
      return this.getCustomers();
    },

    nameErrors() {
      const errors = [];
      if (!this.$v.project.name.$dirty) return errors;
      !this.$v.project.name.required && errors.push('Le nom est obligatoire');
      return errors;
    },

    customersErrors() {
      const errors = [];
      if (!this.$v.project.customers_id.$dirty) return errors;
      !this.$v.project.customers_id.required &&
        errors.push('Le client est obligatoire');
      !this.$v.project.customers_id.number.numeric &&
        errors.push('Le client est obligatoire');
      return errors;
    },
  },

  mounted() {
    if (this.$route.params.id > 0) {
      this.formType = this.formTypes.edit;
      this.project.id = parseInt(this.project.id || this.$route.params.id);
    } else {
      this.formType = this.formTypes.create;
      if (this.defaultCustomersId > 0) {
        this.project.customers_id = parseInt(this.defaultCustomersId);
      }
    }
    this.showLoader();
    this.get_customers()
      .then((response) => {
        console.log('Customers OK', response);
      })
      .catch((error) => {
        console.log('Erreur', error);
        this.snack({
          position: 'bottom',
          align: 'center',
          color: 'error',
          text: 'Obtention des clients impossible : ' + error.message,
        });
      })
      .finally(() => {
        this.showLoader();
      });
  },

  methods: {
    ...mapActions('customer', ['get_customers']),
    ...mapMutations('app', ['showLoader']),

    save() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        if (this.project.id == null || this.project.id <= 0) {
          delete this.project.id;
        }
        this.$emit('save', this.project);
        if (this.formType === this.formTypes.create) {
          this.project = {};
        }
      }
    },
  },
};
</script>
