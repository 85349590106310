// import Vue from 'vue'

// Lib imports
import axios from 'axios'

// constants
import { ENV } from './constant'

// Vue.prototype.$http = axios

const HTTP = axios.create({
  baseURL: ENV,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  params: {}
})

// INTERCEPTORS
// Response
HTTP.interceptors.response.use(
  response => {
    // Do something with response data
    return response
  },
  error => {
    // Do something with response error
    console.log('Error in interceptor', error)
    return Promise.reject(error)
  }
)
// Request
HTTP.interceptors.request.use(
  config => {
    // console.log(config.url)
    if (config.url !== '/sessions' && config.url !== '/users/reset-password') {
      let token = localStorage.getItem('session')
      if (token) {
        config.headers['X-CUSTOM-AUTH-TOKEN'] = token
      }
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

const HTTP_out = axios.create({
  timeout: 5000
})

export { HTTP, HTTP_out }
