export default {
  getSprintUserStories: state => () => {
    return state.sprintUserStories
  },
  getUserStories: state => () => {
    return state.userStories
  },
  getUserStory: state => () => {
    return state.userStory
  }
}
