<template>
  <v-app>
    <core-drawer v-if="!isLoginPage" />
    <core-toolbar v-if="!isLoginPage" />
    <core-view />
  </v-app>
</template>
<script>
export default {
  computed: {
    isLoginPage () {
      return this.$route.name === 'Connexion' || this.$route.name === 'Nous Contacter'
    }
  }
}
</script>
<style lang="scss">
@import '@/styles/index.scss';

/* Remove in 1.2 */
.v-datatable thead th.column.sortable i {
  vertical-align: unset;
}
.v-picker .v-date-picker-table td .v-btn__content{
  color:#222 !important;
}
.v-picker .v-date-picker-table td .v-btn--active .v-btn__content{
  color:#FFF !important;
  font-weight: bold;
}
.negative{
  color:rgb(255, 81, 0) !important;
}
.v-datatable__actions .v-btn {
  color: rgba(0,0,0,.53) !important;
}
.drawer-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
}
.login-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.v-tooltip__content {
  color: #FFF!important;
  background-color: #1b212bad;
}
</style>
